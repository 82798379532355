import React, { createContext, useState,useContext,useEffect } from 'react';
import { getTokenfb, onMessageListener } from './firebase/firebaseconfig.js'
import { Context } from './Context.js';

const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({ title: '', body: '', image: '' });
  const [TokenFound, setTokenFound] = useState("");
  const maincontext = useContext(Context);

   useEffect(()=>{
    maincontext.Updatespecificdata("push_token", TokenFound);    
  },[TokenFound])
  
  
  const askForNotificationPermission = async () => {
    if ('serviceWorker' in navigator) {
      try {
        const registration = await navigator.serviceWorker.ready;
        console.log('Service Worker activo');
        await requestPermission();
      } catch (error) {
        console.error('Error al validar el Service Worker:', error);
      }
    } else {
      console.error('Service Worker no está soportado en este navegador.');
    }
  };

  const requestPermission = async () => {
    try {
      console.log('Requesting notification permission...');
      const permission = await Notification.requestPermission();
      console.log('Notification permission status:', permission);

      if (permission === 'granted') {
        console.log('Notification permission granted.');
        getTokenfb(setTokenFound);
      } else if (permission === 'denied') {
        console.log('Notification permission denied.');
      } else {
        console.log('Notification permission dismissed.');
      }
    } catch (error) {
      console.error('Error getting permission for notifications', error);
    }
  };

   onMessageListener().then(payload => {
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
        image: payload.notification.image || ''
      });
      console.log(payload);
    }).catch(err => console.log('failed: ', err));



  return (
    <NotificationContext.Provider value={{ notification, askForNotificationPermission,setNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationProvider, NotificationContext };
