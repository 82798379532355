import OK from "../../assets/Images/OK.png"
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Context } from '../../context/Context'
import HeaderNologo from '../Universals/HeaderNoLogo';
import SimpleBackdrop from "../Universals/BackDrop";
import InfoDialog from "./InfoDialog";
import { readDocumentById,deleteDocument } from '../../context/puchdb/pouchdbData';
import { NotificationContext} from "../../context/NotificationContext";

function PaymentConfirmation() {
    const datacontext = useContext(Context);
    const txt = datacontext.myConfig.txt[datacontext?.lenguage];
    const navigate = useNavigate();
    const [requestcomplet, setrequestComplete] = useState(false);
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [transactionID, setTransactionID] = useState("");
    const {askForNotificationPermission } = useContext(NotificationContext);
    useEffect(() => {
          if (datacontext.Data?.Datos?.detalles?.length === 0){
            if (datacontext?.comprobante){

                // readDocumentById().then(doc => {
                //     datacontext.UpdateData(doc);
                //     deleteDocument().then().catch(e=>console.log(e))
                //     return;
                // });
                return;
            }
            setTimeout(()=>{  navigate("/");},3000)
          
        } 
        askForNotificationPermission();
    }, [])
    useEffect(() => {
        if (datacontext?.comprobante.includes("Comprobant:")) {
            const partes = datacontext?.comprobante.split("Comprobant:")
            setTransactionID(partes[1].trim());
        }
    }, [datacontext?.comprobante])

    const onOKPost = (respuesta) => {

        datacontext?.myConfig?.allconfigArray.map(value => {
            if (value.concept.includes("URLComprobanteVisitax")) {
                datacontext.setComprobante(value.configurationInfo
                    + respuesta.data.data.voucher);
                setrequestComplete(false);
                return;
            }
        })

        setrequestComplete(false);
    }

    const onFail = (respuesta) => {
        setOpenInfoDialog(true);
        setrequestComplete(false);
    }


    useEffect(() => {
        if (requestcomplet) datacontext.postCompleteTransaction(onOKPost, onFail, transactionID);
    }, [requestcomplet])
    const timer = React.useRef();

    timer.current = setTimeout(() => {
        //navigate("/");
    }, 20000);
    useEffect(()=>{
      console.log(datacontext?.Data?.Datos);
    },[datacontext?.Data?.Datos])

    return (<div className="workzone">
        {openInfoDialog ? <InfoDialog /> : <></>}
        {requestcomplet ? <><SimpleBackdrop /></> : <>
            <div className="backgroundhead2">
                <HeaderNologo navigate="/" />
            </div>
            <h1>{txt[52]}</h1>
            <img className="textura1" src={OK} ></img>
            <h5>${datacontext?.Data?.Datos?.importe?datacontext?.Data?.Datos?.importe:""} MXN</h5>
            {datacontext?.comprobante.includes("Comprobant:") ? <>
                <h3>{txt[53]} <button className="stylebtn1" onClick={() => {
                    setrequestComplete(true);
                }}>{txt[54].toUpperCase()}</button></h3>
            </> : <>
                <h3>{txt[53]} <Link to={datacontext?.comprobante}>{txt[54].toUpperCase()}</Link> </h3>
            </>}
        </>}
    </div>)
    //<h3>Visit <Link>Visitax</Link> to the finest travel promotios on your trip</h3>

} export default PaymentConfirmation;