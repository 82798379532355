import useAdvisorContent from "./hooks/useAdvisorContent";
import InfoCard from "../ContentDashboard/Components/InfoCard/InfoCard";
import { Grid } from "@mui/material";
import Table from "../Analytiscs/components/Table/Table";
import PayloadViewer from "../Analytiscs/components/PayloadModal/PayloadViewer";

const DashboardAsesor = ({ filter = "today", specificfilter = null }) => {
  const { TransactionsUsersOBJ, PaxOBJ,TransactionsList,transactiondata,openPayloadViewer,keysEditablesTransactions,keysVisiblesTransactions,handleTransactionSelected,handleClose } = useAdvisorContent({
    filter,
    specificfilter,
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <InfoCard element={PaxOBJ} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <InfoCard element={TransactionsUsersOBJ} />
        </Grid>

        <Grid item xs={12}>
        <Table
            headers={TransactionsList.headers && TransactionsList.headers}
            content={TransactionsList.data}
            totalElements={TransactionsList.totalSum}
            omitKeys={TransactionsList.omitkeys}
            onPageChange={() => {}}
            title="Transacciones por voucher"
            standardPagination={true}
            actionButtonTitle="Detalles"
            onActionButtonClick={handleTransactionSelected}
          />
        </Grid>
      </Grid>
      {transactiondata.payload && (
        <PayloadViewer
          open={openPayloadViewer}
          onClose={handleClose}
          transactiondata={transactiondata}
          keysVisibles={keysVisiblesTransactions}
          finalActionButtonLabel="Cerrar"
        />
      )}
    </>
  );
};

export default DashboardAsesor;
