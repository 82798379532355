import React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Context } from "../../../context/Context";
import { useContext, useEffect, useState } from "react";

export default function ComplementariForm({
  onCardDataChange = () => {},
  ValidComplementary = () => {},
}) {
  const datacontext = useContext(Context);
  const txt = datacontext.myConfig.txt[datacontext.lenguage];

  const regexName = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/;
  const regexPhone = /^\d{0,15}$/;

  const [cardData, setcardData] = useState({
    holder_name: "",
    phone_number: "",
    email: "",
  });
  const handleChange = (prop) => (event) => {
    if (prop === "holder_name" && regexName.test(event.target.value)) {
      setcardData((prev) => ({ ...prev, holder_name: event.target.value }));
    } else if (prop === "email") {
      const trimmedValue = event.target.value.replace(/\s/g, ""); // Elimina los espacios
      datacontext.Updatespecificdata("correo",trimmedValue);
      setcardData((prev) => ({ ...prev, email: trimmedValue }));
    } else if (prop === "phone_number" && regexPhone.test(event.target.value)) {
      datacontext.Updatespecificdata("telefono", event.target.value);
      setcardData((prev) => ({ ...prev, phone_number: event.target.value }));
    }

    // if (update) datacontext.setcard({
    //     ...datacontext.card,
    //     [prop]: event.target.value
    // });
  };

  useEffect(() => {
    onCardDataChange(cardData);
    const nameValid = cardData.holder_name.length > 1;
    const regexEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const emailValid = regexEmail.test(cardData.email);
    if (nameValid && emailValid) ValidComplementary(true);
    else ValidComplementary(false);
  }, [cardData]);

  return (
    <Box
      component="form"
      sx={{
        display: "flex", // Añade Flexbox
        flexDirection: "column", // Alinea los elementos en una columna
        alignItems: "center", // Centra los elementos horizontalmente
        justifyContent: "center", // Centra los elementos verticalmente
        "& .MuiTextField-root": { m: 1, width: "95%" },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          id="outlined-basic"
          label={txt[36] + ":"}
          variant="outlined"
          value={cardData.holder_name}
          onChange={handleChange("holder_name")}
        />
        <TextField
          id="outlined-basic"
          label={txt[14] + ":"}
          variant="outlined"
          value={cardData.email}
          onChange={handleChange("email")}
        />
        <TextField
          id="outlined-basic"
          label={txt[67]}
          variant="outlined"
          value={cardData.phone_number}
          onChange={handleChange("phone_number")}
        />
      </div>
    </Box>
  );
}
