import { useState } from "react";
import Table from "../Table/Table";
import PayloadViewer from "../PayloadModal/PayloadViewer";
import ActionModal from "../../../../../../Universals/ActionModal";
import useTransactions from "./hooks/useTransactions";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import PaymentIcon from '@mui/icons-material/Payment';

const Transactions = ({ contentdashboard }) => {
  const {
    TransactionsList,
    alltransactionsactive,
    TransactionDetailsList,
    transactiondata,
    openPayloadViewer,
    emailSendStatus,
    keysEditablesTransactions,
    keysVisiblesTransactions,
    handleTransactionSelected,
    handleClose,
    onResenEmailClicked,
    handlePageChange,
    setEmailSendStatus,
  } = useTransactions({ contentdashboard });
  
  const [filterPayments, setFilterPayments] = useState("all");

  const handleFilterChange = (event, newFilter) => {
    if (newFilter !== null) setFilterPayments(newFilter);
  };

  const filteredTransactions = TransactionsList.data.filter((transaction) => {
    if (filterPayments === "all") return true;
    if (filterPayments === "manual") return transaction.manual;
    if (filterPayments === "non-manual") return !transaction.manual;
  });

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ToggleButtonGroup
            value={filterPayments}
            exclusive
            onChange={handleFilterChange}
            aria-label="Filtro de transacciones"
          >
            <ToggleButton value="all" aria-label="Mostrar todas">
              <FormatListBulletedIcon />
            </ToggleButton>
            <ToggleButton value="manual" aria-label="Mostrar solo manuales">
              <PaymentIcon />
            </ToggleButton>
            <ToggleButton value="non-manual" aria-label="Mostrar solo no manuales">
              <TapAndPlayIcon />
            </ToggleButton>
          </ToggleButtonGroup>

          <Table
            headers={TransactionsList.headers && TransactionsList.headers}
            content={filteredTransactions}
            totalElements={TransactionsList.totalSum}
            omitKeys={TransactionsList.omitkeys}
            onPageChange={() => {}}
            title="Transacciones por voucher"
            standardPagination={alltransactionsactive}
            actionButtonTitle="Detalles"
            onActionButtonClick={handleTransactionSelected}
          />
        </Grid>

        <Grid item xs={12}>
          <Table
            headers={TransactionDetailsList.headers && TransactionDetailsList.headers}
            content={TransactionDetailsList.data}
            totalElements={TransactionDetailsList.totalSum}
            omitKeys={TransactionDetailsList.omitkeys}
            onPageChange={handlePageChange}
            title="Transacciones por pasaporte"
            standardPagination={alltransactionsactive}
          />
        </Grid>
      </Grid>

      {transactiondata.payload && (
        <PayloadViewer
          open={openPayloadViewer}
          onClose={handleClose}
          transactiondata={transactiondata}
          keysEditables={keysEditablesTransactions}
          keysVisibles={keysVisiblesTransactions}
          onFinalActionButtonClicked={onResenEmailClicked}
          finalActionButtonLabel="Reenviar correo"
        />
      )}
      <ActionModal
        open={emailSendStatus !== null}
        onClose={() => setEmailSendStatus(null)}
        text={
          emailSendStatus
            ? "El correo se envió exitosamente!"
            : "Ocurrió un error al enviar el correo"
        }
      />
    </>
  );
};

export default Transactions;
