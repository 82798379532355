import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import Planet from "./Planet";
import HeaderNologo from "./HeaderNoLogo";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "85vh",

  color: "#FFFFFF",
  textAlign: "center",
  padding: theme.spacing(2),
}));

const Title = styled(Typography)({
  fontSize: "3rem",
  marginBottom: "1rem",
  color: "#952F57",
  fontWeight: "bold",
  fontFamily: "Candara",
});

const SubTitle = styled(Typography)({
  fontSize: "1.5rem",
  marginBottom: "2rem",
  color: "#1C4755",
  fontWeight: "bold",
  fontFamily: "Candara",
});

const LoadingPlanet = () => {
  return (
    <div className="workzone">
      <HeaderNologo navigate="/ConfirmationUsers" />
      <Container>
        <Title variant="h1">Wait a moment please</Title>
        <SubTitle variant="h2">Completing Transaction...</SubTitle>
        <Planet />
      </Container>
    </div>
  );
};
export default LoadingPlanet;
