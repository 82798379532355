const countries = [
  
    { code: 'AD', label: 'Andorra', phone: '376', passportCode: 'AND', nationality: 'Andorran' },
    { code: 'AE', label: 'United Arab Emirates', phone: '971', passportCode: 'ARE', nationality: 'Emirati' },
    { code: 'AF', label: 'Afghanistan', phone: '93', passportCode: 'AFG', nationality: 'Afghan' },
    { code: 'AG', label: 'Antigua and Barbuda', phone: '1-268', passportCode: 'ATG', nationality: 'Antiguan or Barbudan' },
    { code: 'AI', label: 'Anguilla', phone: '1-264', passportCode: 'AIA', nationality: 'Anguillian' },
    { code: 'AL', label: 'Albania', phone: '355', passportCode: 'ALB', nationality: 'Albanian' },
    { code: 'AM', label: 'Armenia', phone: '374', passportCode: 'ARM', nationality: 'Armenian' },
    { code: 'AO', label: 'Angola', phone: '244', passportCode: 'AGO', nationality: 'Angolan' },
    { code: 'AQ', label: 'Antarctica', phone: '672', passportCode: 'ATA', nationality: 'Antarctic' },
    { code: 'AR', label: 'Argentina', phone: '54', passportCode: 'ARG', nationality: 'Argentine' },
    { code: 'AS', label: 'American Samoa', phone: '1-684', passportCode: 'ASM', nationality: 'American Samoan' },
    { code: 'AT', label: 'Austria', phone: '43', passportCode: 'AUT', nationality: 'Austrian' },
    { code: 'AU', label: 'Australia', phone: '61', suggested: true, passportCode: 'AUS', nationality: 'Australian' },
    { code: 'AW', label: 'Aruba', phone: '297', passportCode: 'ABW', nationality: 'Aruban' },
    { code: 'AX', label: 'Alland Islands', phone: '358', passportCode: 'ALA', nationality: 'Aland Islander' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994', passportCode: 'AZE', nationality: 'Azerbaijani' },
    { code: 'BA', label: 'Bosnia and Herzegovina', phone: '387', passportCode: 'BIH', nationality: 'Bosnian or Herzegovinian' },
    { code: 'BB', label: 'Barbados', phone: '1-246', passportCode: 'BRB', nationality: 'Barbadian' },
    { code: 'BD', label: 'Bangladesh', phone: '880', passportCode: 'BGD', nationality: 'Bangladeshi' },
    { code: 'BE', label: 'Belgium', phone: '32', passportCode: 'BEL', nationality: 'Belgian' },
    { code: 'BF', label: 'Burkina Faso', phone: '226', passportCode: 'BFA', nationality: 'Burkinabe' },
    { code: 'BG', label: 'Bulgaria', phone: '359', passportCode: 'BGR', nationality: 'Bulgarian' },
    { code: 'BH', label: 'Bahrain', phone: '973', passportCode: 'BHR', nationality: 'Bahraini' },
    { code: 'BI', label: 'Burundi', phone: '257', passportCode: 'BDI', nationality: 'Burundian' },
    { code: 'BJ', label: 'Benin', phone: '229', passportCode: 'BEN', nationality: 'Beninese' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590', passportCode: 'BLM', nationality: 'Saint Barthelemy Islander' },
    { code: 'BM', label: 'Bermuda', phone: '1-441', passportCode: 'BMU', nationality: 'Bermudian' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673', passportCode: 'BRN', nationality: 'Bruneian' },
    { code: 'BO', label: 'Bolivia', phone: '591', passportCode: 'BOL', nationality: 'Bolivian' },
    { code: 'BR', label: 'Brazil', phone: '55', passportCode: 'BRA', nationality: 'Brazilian' },
    { code: 'BS', label: 'Bahamas', phone: '1-242', passportCode: 'BHS', nationality: 'Bahamian' },
    { code: 'BT', label: 'Bhutan', phone: '975', passportCode: 'BTN', nationality: 'Bhutanese' },
    { code: 'BV', label: 'Bouvet Island', phone: '47', passportCode: 'BVT', nationality: 'Bouvet Islander' },
    { code: 'BW', label: 'Botswana', phone: '267', passportCode: 'BWA', nationality: 'Motswana' },
    { code: 'BY', label: 'Belarus', phone: '375', passportCode: 'BLR', nationality: 'Belarusian' },
    { code: 'BZ', label: 'Belize', phone: '501', passportCode: 'BLZ', nationality: 'Belizean' },
    { code: 'CA', label: 'Canada', phone: '1', suggested: true, passportCode: 'CAN', nationality: 'Canadian' },
    { code: 'CC', label: 'Cocos (Keeling) Islands', phone: '61', passportCode: 'CCK', nationality: 'Cocos Islander' },
    { code: 'CD', label: 'Congo, Democratic Republic of the', phone: '243', passportCode: 'COD', nationality: 'Congolese (Democratic Republic)' },
    { code: 'CF', label: 'Central African Republic', phone: '236', passportCode: 'CAF', nationality: 'Central African' },
    { code: 'CG', label: 'Congo, Republic of the', phone: '242', passportCode: 'COG', nationality: 'Congolese (Republic)' },
    { code: 'CH', label: 'Switzerland', phone: '41', passportCode: 'CHE', nationality: 'Swiss' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225', passportCode: 'CIV', nationality: 'Ivorian' },
    { code: 'CK', label: 'Cook Islands', phone: '682', passportCode: 'COK', nationality: 'Cook Islander' },
    { code: 'CL', label: 'Chile', phone: '56', passportCode: 'CHL', nationality: 'Chilean' },
    { code: 'CM', label: 'Cameroon', phone: '237', passportCode: 'CMR', nationality: 'Cameroonian' },
    { code: 'CN', label: 'China', phone: '86', passportCode: 'CHN', nationality: 'Chinese' },
    { code: 'CO', label: 'Colombia', phone: '57', passportCode: 'COL', nationality: 'Colombian' },
    { code: 'CR', label: 'Costa Rica', phone: '506', passportCode: 'CRI', nationality: 'Costa Rican' },
    { code: 'CU', label: 'Cuba', phone: '53', passportCode: 'CUB', nationality: 'Cuban' },
    { code: 'CV', label: 'Cape Verde', phone: '238', passportCode: 'CPV', nationality: 'Cape Verdean' },
    { code: 'CW', label: 'Curacao', phone: '599', passportCode: 'CUW', nationality: 'Curacaoan' },
    { code: 'CX', label: 'Christmas Island', phone: '61', passportCode: 'CXR', nationality: 'Christmas Islander' },
    { code: 'CY', label: 'Cyprus', phone: '357', passportCode: 'CYP', nationality: 'Cypriot' },
    { code: 'CZ', label: 'Czech Republic', phone: '420', passportCode: 'CZE', nationality: 'Czech' },
    { code: 'DE', label: 'Germany', phone: '49', suggested: true, passportCode: 'DEU', nationality: 'German' },
    { code: 'DJ', label: 'Djibouti', phone: '253', passportCode: 'DJI', nationality: 'Djiboutian' },
    { code: 'DK', label: 'Denmark', phone: '45', passportCode: 'DNK', nationality: 'Danish' },
    { code: 'DM', label: 'Dominica', phone: '1-767', passportCode: 'DMA', nationality: 'Dominican' },
    { code: 'DO', label: 'Dominican Republic', phone: '1-809', passportCode: 'DOM', nationality: 'Dominican' },
    { code: 'DZ', label: 'Algeria', phone: '213', passportCode: 'DZA', nationality: 'Algerian' },
    { code: 'EC', label: 'Ecuador', phone: '593', passportCode: 'ECU', nationality: 'Ecuadorian' },
    { code: 'EE', label: 'Estonia', phone: '372', passportCode: 'EST', nationality: 'Estonian' },
    { code: 'EG', label: 'Egypt', phone: '20', passportCode: 'EGY', nationality: 'Egyptian' },
    { code: 'EH', label: 'Western Sahara', phone: '212', passportCode: 'ESH', nationality: 'Sahrawi' },
    { code: 'ER', label: 'Eritrea', phone: '291', passportCode: 'ERI', nationality: 'Eritrean' },
    { code: 'ES', label: 'Spain', phone: '34', passportCode: 'ESP', nationality: 'Spanish' },
    { code: 'ET', label: 'Ethiopia', phone: '251', passportCode: 'ETH', nationality: 'Ethiopian' },
    { code: 'FI', label: 'Finland', phone: '358', passportCode: 'FIN', nationality: 'Finnish' },
    { code: 'FJ', label: 'Fiji', phone: '679', passportCode: 'FJI', nationality: 'Fijian' },
    { code: 'FK', label: 'Falkland Islands (Malvinas)', phone: '500', passportCode: 'FLK', nationality: 'Falkland Islander' },
    { code: 'FM', label: 'Micronesia, Federated States of', phone: '691', passportCode: 'FSM', nationality: 'Micronesian' },
    { code: 'FO', label: 'Faroe Islands', phone: '298', passportCode: 'FRO', nationality: 'Faroese' },
    { code: 'FR', label: 'France', phone: '33', suggested: true, passportCode: 'FRA', nationality: 'French' },
    { code: 'GA', label: 'Gabon', phone: '241', passportCode: 'GAB', nationality: 'Gabonese' },
    { code: 'GB', label: 'United Kingdom', phone: '44', passportCode: 'GBR', nationality: 'British' },
    { code: 'GD', label: 'Grenada', phone: '1-473', passportCode: 'GRD', nationality: 'Grenadian' },
    { code: 'GE', label: 'Georgia', phone: '995', passportCode: 'GEO', nationality: 'Georgian' },
    { code: 'GF', label: 'French Guiana', phone: '594', passportCode: 'GUF', nationality: 'French Guianan' },
    { code: 'GG', label: 'Guernsey', phone: '44', passportCode: 'GGY', nationality: 'Guernsey Islander' },
    { code: 'GH', label: 'Ghana', phone: '233', passportCode: 'GHA', nationality: 'Ghanaian' },
    { code: 'GI', label: 'Gibraltar', phone: '350', passportCode: 'GIB', nationality: 'Gibraltar' },
    { code: 'GL', label: 'Greenland', phone: '299', passportCode: 'GRL', nationality: 'Greenlandic' },
    { code: 'GM', label: 'Gambia', phone: '220', passportCode: 'GMB', nationality: 'Gambian' },
    { code: 'GN', label: 'Guinea', phone: '224', passportCode: 'GIN', nationality: 'Guinean' },
    { code: 'GP', label: 'Guadeloupe', phone: '590', passportCode: 'GLP', nationality: 'Guadeloupean' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240', passportCode: 'GNQ', nationality: 'Equatorial Guinean' },
    { code: 'GR', label: 'Greece', phone: '30', passportCode: 'GRC', nationality: 'Greek' },
    { code: 'GT', label: 'Guatemala', phone: '502', passportCode: 'GTM', nationality: 'Guatemalan' },
    { code: 'GU', label: 'Guam', phone: '1-671', passportCode: 'GUM', nationality: 'Guamanian' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245', passportCode: 'GNB', nationality: 'Bissau-Guinean' },
    { code: 'GY', label: 'Guyana', phone: '592', passportCode: 'GUY', nationality: 'Guyanese' },
    { code: 'HK', label: 'Hong Kong', phone: '852', passportCode: 'HKG', nationality: 'Hong Konger' },
    { code: 'HM', label: 'Heard Island and McDonald Islands', phone: '672', passportCode: 'HMD', nationality: 'Heard Island and McDonald Islander' },
    { code: 'HN', label: 'Honduras', phone: '504', passportCode: 'HND', nationality: 'Honduran' },
    { code: 'HR', label: 'Croatia', phone: '385', passportCode: 'HRV', nationality: 'Croatian' },
    { code: 'HT', label: 'Haiti', phone: '509', passportCode: 'HTI', nationality: 'Haitian' },
    { code: 'HU', label: 'Hungary', phone: '36', passportCode: 'HUN', nationality: 'Hungarian' },
    { code: 'ID', label: 'Indonesia', phone: '62', passportCode: 'IDN', nationality: 'Indonesian' },
    { code: 'IE', label: 'Ireland', phone: '353', passportCode: 'IRL', nationality: 'Irish' },
    { code: 'IL', label: 'Israel', phone: '972', passportCode: 'ISR', nationality: 'Israeli' },
    { code: 'IM', label: 'Isle of Man', phone: '44', passportCode: 'IMN', nationality: 'Manx' },
    { code: 'IN', label: 'India', phone: '91', passportCode: 'IND', nationality: 'Indian' },
    { code: 'IO', label: 'British Indian Ocean Territory', phone: '246', passportCode: 'IOT', nationality: 'British Indian Ocean Territory' },
    { code: 'IQ', label: 'Iraq', phone: '964', passportCode: 'IRQ', nationality: 'Iraqi' },
    { code: 'IR', label: 'Iran, Islamic Republic of', phone: '98', passportCode: 'IRN', nationality: 'Iranian' },
    { code: 'IS', label: 'Iceland', phone: '354', passportCode: 'ISL', nationality: 'Icelander' },
    { code: 'IT', label: 'Italy', phone: '39', passportCode: 'ITA', nationality: 'Italian' },
    { code: 'JE', label: 'Jersey', phone: '44', passportCode: 'JEY', nationality: 'Jersey' },
    { code: 'JM', label: 'Jamaica', phone: '1-876', passportCode: 'JAM', nationality: 'Jamaican' },
    { code: 'JO', label: 'Jordan', phone: '962', passportCode: 'JOR', nationality: 'Jordanian' },
    { code: 'JP', label: 'Japan', phone: '81', passportCode: 'JPN', nationality: 'Japanese' },
    { code: 'KE', label: 'Kenya', phone: '254', passportCode: 'KEN', nationality: 'Kenyan' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996', passportCode: 'KGZ', nationality: 'Kyrgyzstani' },
    { code: 'KH', label: 'Cambodia', phone: '855', passportCode: 'KHM', nationality: 'Cambodian' },
    { code: 'KI', label: 'Kiribati', phone: '686', passportCode: 'KIR', nationality: 'I-Kiribati' },
    { code: 'KM', label: 'Comoros', phone: '269', passportCode: 'COM', nationality: 'Comorian' },
    { code: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869', passportCode: 'KNA', nationality: 'Kittitian or Nevisian' },
    { code: 'KP', label: "Korea, Democratic People's Republic of", phone: '850', passportCode: 'PRK', nationality: 'North Korean' },
    { code: 'KR', label: 'Korea, Republic of', phone: '82', passportCode: 'KOR', nationality: 'South Korean' },
    { code: 'KW', label: 'Kuwait', phone: '965', passportCode: 'KWT', nationality: 'Kuwaiti' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345', passportCode: 'CYM', nationality: 'Caymanian' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7', passportCode: 'KAZ', nationality: 'Kazakhstani' },
    { code: 'LA', label: "Lao People's Democratic Republic", phone: '856', passportCode: 'LAO', nationality: 'Lao' },
    { code: 'LB', label: 'Lebanon', phone: '961', passportCode: 'LBN', nationality: 'Lebanese' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758', passportCode: 'LCA', nationality: 'Saint Lucian' },
    { code: 'LI', label: 'Liechtenstein', phone: '423', passportCode: 'LIE', nationality: 'Liechtenstein' },
    { code: 'LK', label: 'Sri Lanka', phone: '94', passportCode: 'LKA', nationality: 'Sri Lankan' },
    { code: 'LR', label: 'Liberia', phone: '231', passportCode: 'LBR', nationality: 'Liberian' },
    { code: 'LS', label: 'Lesotho', phone: '266', passportCode: 'LSO', nationality: 'Mosotho' },
    { code: 'LT', label: 'Lithuania', phone: '370', passportCode: 'LTU', nationality: 'Lithuanian' },
    { code: 'LU', label: 'Luxembourg', phone: '352', passportCode: 'LUX', nationality: 'Luxembourgish' },
    { code: 'LV', label: 'Latvia', phone: '371', passportCode: 'LVA', nationality: 'Latvian' },
    { code: 'LY', label: 'Libya', phone: '218', passportCode: 'LBY', nationality: 'Libyan' },
    { code: 'MA', label: 'Morocco', phone: '212', passportCode: 'MAR', nationality: 'Moroccan' },
    { code: 'MC', label: 'Monaco', phone: '377', passportCode: 'MCO', nationality: 'Mon\u00e9gasque' },
    { code: 'MD', label: 'Moldova, Republic of', phone: '373', passportCode: 'MDA', nationality: 'Moldovan' },
    { code: 'ME', label: 'Montenegro', phone: '382', passportCode: 'MNE', nationality: 'Montenegrin' },
    { code: 'MF', label: 'Saint Martin (French part)', phone: '590', passportCode: 'MAF', nationality: 'Saint-Martinoise' },
    { code: 'MG', label: 'Madagascar', phone: '261', passportCode: 'MDG', nationality: 'Malagasy' },
    { code: 'MH', label: 'Marshall Islands', phone: '692', passportCode: 'MHL', nationality: 'Marshallese' },
    { code: 'MK', label: 'Macedonia, the Former Yugoslav Republic of', phone: '389', passportCode: 'MKD', nationality: 'Macedonian' },
    { code: 'ML', label: 'Mali', phone: '223', passportCode: 'MLI', nationality: 'Malian' },
    { code: 'MM', label: 'Myanmar', phone: '95', passportCode: 'MMR', nationality: 'Burmese' },
    { code: 'MN', label: 'Mongolia', phone: '976', passportCode: 'MNG', nationality: 'Mongolian' },
    { code: 'MO', label: 'Macao', phone: '853', passportCode: 'MAC', nationality: 'Macanese' },
    { code: 'MP', label: 'Northern Mariana Islands', phone: '1-670', passportCode: 'MNP', nationality: 'Northern Marianan' },
    { code: 'MQ', label: 'Martinique', phone: '596', passportCode: 'MTQ', nationality: 'Martinican' },
    { code: 'MR', label: 'Mauritania', phone: '222', passportCode: 'MRT', nationality: 'Mauritanian' },
    { code: 'MS', label: 'Montserrat', phone: '1-664', passportCode: 'MSR', nationality: 'Montserratian' },
    { code: 'MT', label: 'Malta', phone: '356', passportCode: 'MLT', nationality: 'Maltese' },
    { code: 'MU', label: 'Mauritius', phone: '230', passportCode: 'MUS', nationality: 'Mauritian' },
    { code: 'MV', label: 'Maldives', phone: '960', passportCode: 'MDV', nationality: 'Maldivian' },
    { code: 'MW', label: 'Malawi', phone: '265', passportCode: 'MWI', nationality: 'Malawian' },
    { code: 'MX', label: 'Mexico', phone: '52', passportCode: 'MEX', nationality: 'Mexican' },
    { code: 'MY', label: 'Malaysia', phone: '60', passportCode: 'MYS', nationality: 'Malaysian' },
    { code: 'MZ', label: 'Mozambique', phone: '258', passportCode: 'MOZ', nationality: 'Mozambican' },
    { code: 'NA', label: 'Namibia', phone: '264', passportCode: 'NAM', nationality: 'Namibian' },
    { code: 'NC', label: 'New Caledonia', phone: '687', passportCode: 'NCL', nationality: 'New Caledonian' },
    { code: 'NE', label: 'Niger', phone: '227', passportCode: 'NER', nationality: 'Nigerien' },
    { code: 'NF', label: 'Norfolk Island', phone: '672', passportCode: 'NFK', nationality: 'Norfolk Islander' },
    { code: 'NG', label: 'Nigeria', phone: '234', passportCode: 'NGA', nationality: 'Nigerian' },
    { code: 'NI', label: 'Nicaragua', phone: '505', passportCode: 'NIC', nationality: 'Nicaraguan' },
    { code: 'NL', label: 'Netherlands', phone: '31', passportCode: 'NLD', nationality: 'Dutch' },
    { code: 'NO', label: 'Norway', phone: '47', passportCode: 'NOR', nationality: 'Norwegian' },
    { code: 'NP', label: 'Nepal', phone: '977', passportCode: 'NPL', nationality: 'Nepali' },
    { code: 'NR', label: 'Nauru', phone: '674', passportCode: 'NRU', nationality: 'Nauruan' },
    { code: 'NU', label: 'Niue', phone: '683', passportCode: 'NIU', nationality: 'Niuean' },
    { code: 'NZ', label: 'New Zealand', phone: '64', passportCode: 'NZL', nationality: 'New Zealand' },
    { code: 'OM', label: 'Oman', phone: '968', passportCode: 'OMN', nationality: 'Omani' },
    { code: 'PA', label: 'Panama', phone: '507', passportCode: 'PAN', nationality: 'Panamanian' },
    { code: 'PE', label: 'Peru', phone: '51', passportCode: 'PER', nationality: 'Peruvian' },
    { code: 'PF', label: 'French Polynesia', phone: '689', passportCode: 'PYF', nationality: 'French Polynesian' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675', passportCode: 'PNG', nationality: 'Papua New Guinean' },
    { code: 'PH', label: 'Philippines', phone: '63', passportCode: 'PHL', nationality: 'Filipino' },
    { code: 'PK', label: 'Pakistan', phone: '92', passportCode: 'PAK', nationality: 'Pakistani' },
    { code: 'PL', label: 'Poland', phone: '48', passportCode: 'POL', nationality: 'Polish' },
    { code: 'PM', label: 'Saint Pierre and Miquelon', phone: '508', passportCode: 'SPM', nationality: 'Saint-Pierrais or Miquelonnais' },
    { code: 'PN', label: 'Pitcairn', phone: '870', passportCode: 'PCN', nationality: 'Pitcairn Islander' },
    { code: 'PR', label: 'Puerto Rico', phone: '1', passportCode: 'PRI', nationality: 'Puerto Rican' },
    { code: 'PT', label: 'Portugal', phone: '351', passportCode: 'PRT', nationality: 'Portuguese' },
    { code: 'PW', label: 'Palau', phone: '680', passportCode: 'PLW', nationality: 'Palauan' },
    { code: 'PY', label: 'Paraguay', phone: '595', passportCode: 'PRY', nationality: 'Paraguayan' },
    { code: 'QA', label: 'Qatar', phone: '974', passportCode: 'QAT', nationality: 'Qatari' },
    { code: 'RE', label: 'R\u00e9union', phone: '262', passportCode: 'REU', nationality: 'R\u00e9unionese' },
    { code: 'RO', label: 'Romania', phone: '40', passportCode: 'ROU', nationality: 'Romanian' },
    { code: 'RS', label: 'Serbia', phone: '381', passportCode: 'SRB', nationality: 'Serbian' },
    { code: 'RU', label: 'Russian Federation', phone: '7', passportCode: 'RUS', nationality: 'Russian' },
    { code: 'RW', label: 'Rwanda', phone: '250', passportCode: 'RWA', nationality: 'Rwandan' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966', passportCode: 'SAU', nationality: 'Saudi Arabian' },
    { code: 'SB', label: 'Solomon Islands', phone: '677', passportCode: 'SLB', nationality: 'Solomon Islander' },
    { code: 'SC', label: 'Seychelles', phone: '248', passportCode: 'SYC', nationality: 'Seychellois' },
    { code: 'SD', label: 'Sudan', phone: '249', passportCode: 'SDN', nationality: 'Sudanese' },
    { code: 'SE', label: 'Sweden', phone: '46', passportCode: 'SWE', nationality: 'Swedish' },
    { code: 'SG', label: 'Singapore', phone: '65', passportCode: 'SGP', nationality: 'Singaporean' },
    { code: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha', phone: '290', passportCode: 'SHN', nationality: 'Saint Helenian' },
    { code: 'SI', label: 'Slovenia', phone: '386', passportCode: 'SVN', nationality: 'Slovene' },
    { code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '47', passportCode: 'SJM', nationality: 'Svalbard and Jan Mayen Islander' },
    { code: 'SK', label: 'Slovakia', phone: '421', passportCode: 'SVK', nationality: 'Slovak' },
    { code: 'SL', label: 'Sierra Leone', phone: '232', passportCode: 'SLE', nationality: 'Sierra Leonean' },
    { code: 'SM', label: 'San Marino', phone: '378', passportCode: 'SMR', nationality: 'San Marinese' },
    { code: 'SN', label: 'Senegal', phone: '221', passportCode: 'SEN', nationality: 'Senegalese' },
    { code: 'SO', label: 'Somalia', phone: '252', passportCode: 'SOM', nationality: 'Somali' },
    { code: 'SR', label: 'Suriname', phone: '597', passportCode: 'SUR', nationality: 'Surinamese' },
    { code: 'SS', label: 'South Sudan', phone: '211', passportCode: 'SSD', nationality: 'South Sudanese' },
    { code: 'ST', label: 'Sao Tome and Principe', phone: '239', passportCode: 'STP', nationality: 'S\u00e3o Tom\u00e9 and Pr\u00edncipe' },
    { code: 'SV', label: 'El Salvador', phone: '503', passportCode: 'SLV', nationality: 'Salvadoran' },
    { code: 'SX', label: 'Sint Maarten (Dutch part)', phone: '1-721', passportCode: 'SXM', nationality: 'Sint Maarten' },
    { code: 'SY', label: 'Syrian Arab Republic', phone: '963', passportCode: 'SYR', nationality: 'Syrian' },
    { code: 'SZ', label: 'Swaziland', phone: '268', passportCode: 'SWZ', nationality: 'Swazi' },
    { code: 'TC', label: 'Turks and Caicos Islands', phone: '1-649', passportCode: 'TCA', nationality: 'Turks and Caicos Islander' },
    { code: 'TD', label: 'Chad', phone: '235', passportCode: 'TCD', nationality: 'Chadian' },
    { code: 'TF', label: 'French Southern Territories', phone: '262', passportCode: 'ATF', nationality: 'French Southern Territories' },
    { code: 'TG', label: 'Togo', phone: '228', passportCode: 'TGO', nationality: 'Togolese' },
    { code: 'TH', label: 'Thailand', phone: '66', passportCode: 'THA', nationality: 'Thai' },
    { code: 'TJ', label: 'Tajikistan', phone: '992', passportCode: 'TJK', nationality: 'Tajik' },
    { code: 'TK', label: 'Tokelau', phone: '690', passportCode: 'TKL', nationality: 'Tokelauan' },
    { code: 'TL', label: 'Timor-Leste', phone: '670', passportCode: 'TLS', nationality: 'Timorese' },
    { code: 'TM', label: 'Turkmenistan', phone: '993', passportCode: 'TKM', nationality: 'Turkmen' },
    { code: 'TN', label: 'Tunisia', phone: '216', passportCode: 'TUN', nationality: 'Tunisian' },
    { code: 'TO', label: 'Tonga', phone: '676', passportCode: 'TON', nationality: 'Tongan' },
    { code: 'TR', label: 'Turkey', phone: '90', passportCode: 'TUR', nationality: 'Turkish' },
    { code: 'TT', label: 'Trinidad and Tobago', phone: '1-868', passportCode: 'TTO', nationality: 'Trinidadian or Tobagonian' },
    { code: 'TV', label: 'Tuvalu', phone: '688', passportCode: 'TUV', nationality: 'Tuvaluan' },
    { code: 'TZ', label: 'Tanzania, United Republic of', phone: '255', passportCode: 'TZA', nationality: 'Tanzanian' },
    { code: 'UA', label: 'Ukraine', phone: '380', passportCode: 'UKR', nationality: 'Ukrainian' },
    { code: 'UG', label: 'Uganda', phone: '256', passportCode: 'UGA', nationality: 'Ugandan' },
    { code: 'US', label: 'United States', phone: '1', passportCode: 'USA', suggested: true, nationality: 'American' },
    { code: 'UY', label: 'Uruguay', phone: '598', passportCode: 'URY', nationality: 'Uruguayan' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998', passportCode: 'UZB', nationality: 'Uzbekistani' },
    { code: 'VA', label: 'Holy See (Vatican City State)', phone: '379', passportCode: 'VAT', nationality: 'Vatican' },
    { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784', passportCode: 'VCT', nationality: 'Saint Vincentian' },
    { code: 'VE', label: 'Venezuela, Bolivarian Republic of', phone: '58', passportCode: 'VEN', nationality: 'Venezuelan' },
    { code: 'VG', label: 'Virgin Islands, British', phone: '1-284', passportCode: 'VGB', nationality: 'British Virgin Island' },
    { code: 'VI', label: 'Virgin Islands, U.S.', phone: '1-340', passportCode: 'VIR', nationality: 'U.S. Virgin Island' },
    { code: 'VN', label: 'Viet Nam', phone: '84', passportCode: 'VNM', nationality: 'Vietnamese' },
    { code: 'VU', label: 'Vanuatu', phone: '678', passportCode: 'VUT', nationality: 'Ni-Vanuatu' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681', passportCode: 'WLF', nationality: 'Wallis and Futuna Islander' },
    { code: 'WS', label: 'Samoa', phone: '685', passportCode: 'WSM', nationality: 'Samoan' },
    { code: 'YE', label: 'Yemen', phone: '967', passportCode: 'YEM', nationality: 'Yemeni' },
    { code: 'YT', label: 'Mayotte', phone: '262', passportCode: 'MYT', nationality: 'Mahoran' },
    { code: 'ZA', label: 'South Africa', phone: '27', passportCode: 'ZAF', nationality: 'South African' },
    { code: 'ZM', label: 'Zambia', phone: '260', passportCode: 'ZMB', nationality: 'Zambian' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263', passportCode: 'ZWE', nationality: 'Zimbabwean' },
  
  ];
  
  export default countries;
  