import usePreTransactions from "./hooks/usePretransactions";
import PayloadViewer from "../PayloadModal/PayloadViewer";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import PaymentIcon from '@mui/icons-material/Payment';
import Table from "../Table/Table";
import { useState } from "react";

const Pretransactions = ({ contentdashboard }) => {
  const {
    PreTransactionsList,
    alltransactionsactive,
    pretransactiondata,
    openPayloadViewer,
    keysEditablesTransactions,
    keysVisiblesTransactions,
    handleTransactionSelected,
    handleClose,
  } = usePreTransactions({ contentdashboard });

  const [filterPayments, setFilterPayments] = useState("all");

  const handleFilterChange = (event, newFilter) => {
    if (newFilter !== null) setFilterPayments(newFilter);
  };

  const filteredTransactions = PreTransactionsList?.data?.filter(
    (transaction) => {
      if (filterPayments === "all") return true;
      if (filterPayments === "manual") return transaction.manual;
      if (filterPayments === "non-manual") return !transaction.manual;
    }
  );
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ToggleButtonGroup
            value={filterPayments}
            exclusive
            onChange={handleFilterChange}
            aria-label="Filtro de transacciones"
          >
            <ToggleButton value="all" aria-label="Mostrar todas">
              <FormatListBulletedIcon />
            </ToggleButton>
            <ToggleButton value="manual" aria-label="Mostrar solo manuales">
              <PaymentIcon />
            </ToggleButton>
            <ToggleButton
              value="non-manual"
              aria-label="Mostrar solo no manuales"
            >
              <TapAndPlayIcon />
            </ToggleButton>
          </ToggleButtonGroup>

          <Table
            headers={PreTransactionsList.headers && PreTransactionsList.headers}
            content={filteredTransactions}
            totalElements={PreTransactionsList.totalSum}
            omitKeys={PreTransactionsList.omitkeys}
            onPageChange={() => {}}
            title="Pre-Transacciones"
            standardPagination={alltransactionsactive}
            actionButtonTitle="Detalles"
            onActionButtonClick={handleTransactionSelected}
          />
        </Grid>
      </Grid>

      {pretransactiondata.payload && (
        <PayloadViewer
          open={openPayloadViewer}
          onClose={handleClose}
          transactiondata={pretransactiondata}
          keysEditables={keysEditablesTransactions}
          keysVisibles={keysVisiblesTransactions}
          finalactionbuttondisable={true}
          editbuttondisable={true}
        />
      )}
    </>
  );
};
export default Pretransactions;
