
import { Link } from 'react-router-dom';
import { Person } from "../../data/Datos";
import * as React from 'react';
import { useContext } from 'react';
import arrow from "../../assets/Images/arrow-inicio.png"
import { Context } from '../../context/Context';
import HeaderNologo from '../Universals/HeaderNoLogo';
import ModalText from '../Universals/ModalText';
import { deleteDocument } from '../../context/puchdb/pouchdbData';


function TravelData() {
  const datacontext = useContext(Context);
  const txt = datacontext.myConfig.txt[datacontext.lenguage];

  const [errors, setErrors] = React.useState({
    date: false,
    visitors: false,

  });
  const [validations, setValidations] = React.useState({
    date: false,
    visitors: false,
    termsandconditions: false,
  });
  const [NumberofVisitors, setNumberofVisitors] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [minDate, setMinDate] = React.useState('');



  React.useEffect(() => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Enero es 0
    const year = today.getFullYear();

    setMinDate(`${year}-${month}-${day}`);
    try {
      deleteDocument("dataPayment");
  } catch (e) { console.log(e) }

  }, []);

  const onDateChange = (event) => {
    setValidations({
      ...validations,
      date: true,
    })

    datacontext.updateTraveldataobj({
      ...datacontext.Data.Datos,
      "fecha_salida": event.target.value,
    });


  }

  const onVisitorsChange = (event) => {
    const value = event.target.value;

    if (value === '') {
      setValidations({ ...validations, visitors: false });
      setErrors({ ...errors, visitors: false });
      setNumberofVisitors('');
    } else {
      const numberValue = Number(value);
      if (Number.isInteger(numberValue) && numberValue > 0 && numberValue <= datacontext.limitvisitors) {
        setValidations({ ...validations, visitors: true });
        setErrors({ ...errors, visitors: false });
        setNumberofVisitors(numberValue);
      } else {
        setErrors({ ...errors, visitors: true });
        setValidations({ ...validations, visitors: false });
      }
    }
  }






  
  
  return (
    <>
      <div className="workzone">
        <ModalText open={open} setOpen={setOpen}></ModalText>
        <HeaderNologo navigate="/PrincipalMenu" />
        <h1>{txt[11]}</h1>
  
        <li>
          <strong id='form1'>{txt[12]}</strong>
          <input 
            type="date" 
            id={errors.date ? "forminputerror" : "forminput"} 
            name="datetime" 
            onChange={onDateChange} 
            min={minDate} 
            onKeyPress={(event) => { event.preventDefault(); }} 
          />
        </li>
        {
          validations.date &&
          <li>
            <strong id='form1'>{txt[13]}</strong>
            <input 
              type="number" 
              id={errors.visitors ? "forminputerror" : "forminput"} 
              name="number" 
              onChange={onVisitorsChange} 
              value={NumberofVisitors} 
            />
          </li>
        }
      
        {
          validations.date && validations.visitors && 
          <>
            <p id="aviso"> {txt[17]} </p>
            <input 
              type="checkbox" 
              className="terms" 
              value="checkbox" 
              onChange={(event) => {
                if (!validations.termsandconditions) setOpen(true);
                setValidations({ ...validations, termsandconditions: event.currentTarget.checked });
              }} 
            />
            <Link className="terms" htmlFor="terms" onClick={() => { setOpen(true) }}>{txt[16]}</Link>
          </>
        }
  
        {
          validations.date && validations.visitors && validations.termsandconditions && datacontext.myConfig.umaValue != null && 
          <>
            <Link 
              onClick={() => { 
                datacontext.UpdateVisitors(NumberofVisitors);
                console.log('Calling requestPermission...');
          
              }} 
              style={{ textDecoration: 'none' }} 
              to="/NumberofUsers" 
              className="stylebtn1"
            >
              {txt[18]} <img id="continue" src={arrow} alt="Visitax logo" />
            </Link>
          </>
        }
      </div>
    </>
  );

} export default TravelData;