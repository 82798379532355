import { Box, Toolbar, Container } from "@mui/material";
import Filters from "./Components/Filters/Filters";
import ContentDashboard from "./Components/ContentDashboard/ContenDashboard";
import Analytics from "./Components/Analytiscs/Analytics";
import DashboardAsesor from "./Components/DashboardAsesor/DashboardAsesor"
import Reports from "./Components/Reports/Reports";
import { useEffect, useState } from "react";


const MainDashboard = ({ selector = 1 }) => {
    const staticfilterhandler = (filter) => { setFilter(filter) };
    const specificFilterClick = (specificfilter) => { setSpecificfilter(specificfilter) };
    const [filter, setFilter] = useState("today");
    const [specificfilter, setSpecificfilter] = useState(null);

    useEffect(() => {
        if (!filter.includes("select")) setSpecificfilter(null);
    }, [filter]);

    const renderContent = () => {
        switch (selector) {
            case 1:
                return <ContentDashboard filter={filter} specificfilter={specificfilter} />;
            case 2:
                return <Analytics filter={filter} specificfilter={specificfilter} />
            case 3:
                return <Reports filter={filter} specificfilter={specificfilter} />;
            case 4:
                return <DashboardAsesor filter={filter} specificfilter={specificfilter} />;
            default:
                return <ContentDashboard filter={filter} specificfilter={specificfilter} />;
        }
    };

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                overflow: 'auto',
            }}
        >
            <Toolbar />
            <Container maxWidth={false} sx={{ mt: 4, mb: 4, px: { xs: 2, sm: 3, md: 4, lg: 5 } }}>
                <Filters staticfilterhandler={staticfilterhandler} specificFilterClick={specificFilterClick} />
                {renderContent()}
            </Container>

        </Box>
    );
};

export default MainDashboard;
