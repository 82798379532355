import line from '../../assets/Images/divider-numU.svg'
import { Link } from 'react-router-dom';
import OK from "../../assets/Images/OK.png"
import Amoount from "../Universals/Amount";
import arrow from "../../assets/Images/arrow-inicio.png"
import { useContext, useEffect } from 'react';
import { Context } from '../../context/Context'
import { useNavigate } from 'react-router-dom';
import HeaderNologo from '../Universals/HeaderNoLogo';


function ConfirmationUsers() {
    const datacontext = useContext(Context);
    const txt = datacontext.myConfig.txt[datacontext.lenguage];
    const navigate = useNavigate();
    useEffect(() => {
        if (datacontext.Data.Datos.detalles.length === 0) navigate("/TravelData");
    })
    const cardconfirmation = (id, Name) => {
        const User = txt[21] + id

        return (
            <div  key={id}>
                <p  className='p2'>{User}</p>
                <div id="cardzone2">
                    <h3>{Name}</h3>
                    <p id='form1'>{txt[41]}</p>
                    <img id="ok" src={OK} ></img>
                </div>
            </div>)
    }


    return (<div className="workzone">
        <HeaderNologo navigate="/NumberofUsers" />
        <h1>{txt[43]}</h1>
        {
            datacontext.Data.Datos.detalles.map((value, id) => {
                return (
                    cardconfirmation(id + 1,
                        datacontext.Data.Datos.detalles[id].nombre + " " +
                        datacontext.Data.Datos.detalles[id].apellido)
                )

            })
        }
        <img className="divider" src={line} ></img>

        <Amoount />
        <Link style={{ textDecoration: 'none' }} to="/TotalPayment" className="stylebtn1" >{txt[18]}<img id="continue" src={arrow} alt="Visitax logo"></img></Link>

    </div>)

} export default ConfirmationUsers;