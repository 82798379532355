import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chart from '../Chart';
import Deposits from '../Deposits';
import Orders from '../Orders';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import FilterDash from '../PrincipalDashboard/FiltersDash';
import { useState, useContext, useEffect, useRef } from 'react';
import { Context } from '../../../context/Context';
import SimpleBackdrop from '../../Universals/BackDrop';
import { getCurrentDate, month, year } from '../../../data/Datos';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import * as XLSX from 'xlsx';

const exportToExcel = (Data) => {
    // Crear un nuevo libro de trabajo
    let wb = XLSX.utils.book_new();

    // Crear algunos datos
    let data = [
        ["Consecutivo", "Transacción", "Fecha", "Creado por :"]
    ];


    if (Data.alltransaction != null) {
        Data.alltransaction.map((value, id) => {
            data.push([id, value.idTransaction, value.createdAt, value.createdBy])
        })
    }


    // Convertir los datos a una hoja de trabajo
    let ws = XLSX.utils.aoa_to_sheet(data);

    // Añadir la hoja de trabajo al libro de trabajo
    XLSX.utils.book_append_sheet(wb, ws, "Hoja1");

    // Escribir el libro de trabajo en un archivo
    XLSX.writeFile(wb, "libro.xlsx");
};


function UserDashboard({ user }) {

    const [filter, setFilter] = useState("Today");
    const [ListData, setListData] = useState(null);
    const [CompleteListData, setCompleteListData] = useState(null);
    const [request, setnewRequest] = useState(true);
    const [request2, setnewRequest2] = useState(true);
    const [page, setPage] = useState(1);
    const registPerPage = 5;
    const [FiltersData, setFilterData] = useState([
        "?StartDate=" + getCurrentDate() + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + page + "&PageSize=" + registPerPage + "&id=" + user.id,
        "?StartDate=" + year() + "/" + month() + "/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + page + "&PageSize=" + registPerPage + "&id=" + user.id,
        "?StartDate=" + year() + "/01/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + page + "&PageSize=" + registPerPage + "&id=" + user.id,
        ""
    ]);
    const datacontext = useContext(Context);
    const OnDataRecived = (response) => {
        if (response.data.data == null) setListData("Empty");
        else setListData(response.data.data)
        setnewRequest(false);
    }
    const OnDataFailed = (response) => {
        setnewRequest(false);
    }
    const OnDataRecived2 = (response) => {
        if (response.data.data == null) setCompleteListData("Empty");
        else setCompleteListData(response.data.data)
        setnewRequest2(false);
    }
    const OnDataFailed2 = (response) => {
        setnewRequest2(false);
    }




    const newRequest = (all) => {

        setListData(null);
        let url3 = FiltersData[3];
        let pageNumberIndex = url3.indexOf("&PageNumber=");
        if (pageNumberIndex !== -1) {
            url3 = url3.substring(0, pageNumberIndex);
        }
        setFilterData([
            "?StartDate=" + getCurrentDate() + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + page + "&PageSize=" + registPerPage + "&id=" + user.id,
            "?StartDate=" + year() + "/" + month() + "/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + page + "&PageSize=" + registPerPage + "&id=" + user.id,
            "?StartDate=" + year() + "/01/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + page + "&PageSize=" + registPerPage + "&id=" + user.id,
            url3 + "&PageNumber=" + page + "&PageSize=" + registPerPage + "&id=" + user.id])
        if (filter.includes("Today")) datacontext.getTransactionUser(all?OnDataRecived2:OnDataRecived, all?OnDataFailed2:OnDataFailed, FiltersData[0], all);
        if (filter.includes("Month")) datacontext.getTransactionUser(all?OnDataRecived2:OnDataRecived, all?OnDataFailed2:OnDataFailed, FiltersData[1], all);
        if (filter.includes("Year")) datacontext.getTransactionUser(all?OnDataRecived2:OnDataRecived, all?OnDataFailed2:OnDataFailed, FiltersData[2], all);
        if (filter.includes("Select")) datacontext.getTransactionUser(all?OnDataRecived2:OnDataRecived, all?OnDataFailed2:OnDataFailed, FiltersData[3], all);
    }



    useEffect(() => {
        if (request === true) { console.log("Pidiendo paginado"); newRequest(false);  setnewRequest2(true);}
       
    }, [request])

    useEffect(() => {
        if (request2 === true) { console.log("Pidiendo generales"); newRequest(true); }
    }, [request2])
    useEffect(() => {
        setnewRequest(true);
    }, [page])


    return (<>

        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '50vh',
                overflow: 'auto',
                padding: 0
            }}
        >
            <Toolbar />
            {ListData == null ? <SimpleBackdrop /> : <>
                <Container maxWidth="lg" sx={{ mt: -5, mb: 1 }}>
                    <Grid container spacing={2}>

                        <Grid item xs={10} md={2} lg={10}>

                            <FilterDash filter={filter} setFilter={setFilter} setnewRequest={setnewRequest} FiltersData={FiltersData} setFilterData={setFilterData} page={page} registPerPage={registPerPage} />
                        </Grid>

                        <Grid item xs={5} md={2} lg={2}>
                            <Button variant="outlined" startIcon={<DownloadIcon />} onClick={() => { exportToExcel(CompleteListData) }}>
                                Export Report
                            </Button>
                        </Grid>
                    </Grid>

                    <>
                        <Grid container spacing={2}>


                            <Grid item xs={3} md={2} lg={2}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 240,
                                    }}
                                >
                                    <Deposits data={{ totalSum: ListData.totalSum }} type={2} />
                                </Paper>
                            </Grid>

                            {/* Recent Deposits */}
                            <Grid item xs={3} md={2} lg={2}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 240,
                                    }}
                                >
                                    <Deposits data={{
                                        totalSum: ListData.totalPerson
                                    }} type={3} />
                                </Paper>
                            </Grid>

                            {/* Recent Orders */}
                            <Grid item xs={8}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                    {ListData == null ? <SimpleBackdrop /> : <Orders data={ListData} page={page} setPage={setPage} registPerPage={registPerPage} setnewRequest={setnewRequest} type={3} />}
                                </Paper>
                            </Grid>

                        </Grid>

                    </>



                </Container>

            </>}







        </Box >


    </>);
} export default UserDashboard;