import * as React from "react";
import visitaxlogo from "../../assets/Images/LogoVisitaxAvionColor.png";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Context } from "../../context/Context";
import { useContext, useState, useEffect } from "react";
import SimpleBackdrop from "../Universals/BackDrop";
import { useNavigate, useLocation } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { CircularProgress, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// TODO remove, this demo shouldn't need to reset the theme.

function ChangePassword() {
  const datacontext = useContext(Context);
  const [password, setPassword] = useState("");
  const [user, setUser] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [waitingResponse, setWaitingResponse] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [badRequestAlert, setBadRequestAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const userParam = searchParams.get("user");
    setUser(userParam);
  }, [location]);

  const onpasswordchange = (response) => {
    setWaitingResponse(false);
    setSuccessAlert(true);
    setIsButtonDisabled(true)
    setTimeout(() => {
      navigate('/Login')
    }, 2000);
    
  };
  const passworfaile = (error) => {
    setWaitingResponse(false);
    if (error.response && error.response.status === 400) {
      setBadRequestAlert(true);
      setIsButtonDisabled(true)
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setPasswordsMatch(false);
      return;
    }
    setWaitingResponse(true);
    datacontext.postChangePassword(onpasswordchange, passworfaile, {
      user: user,
      password: password,
    });
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    if (!passwordsMatch) {
      setPasswordsMatch(true);
    }
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    if (!passwordsMatch) {
      setPasswordsMatch(true);
    }
  };
  return (
    <div className="workzone">
      <div className="backgroundhead1"></div>
      <ThemeProvider theme={datacontext.primaytheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img className="visitaxlogo" src={visitaxlogo} alt="Visitax logo" />
            <Typography component="h1" variant="h5">
              Change Password
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="New Password"
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={handlePasswordChange}
                InputProps={{
                  // Add IconButton to toggle visibility
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm New Password"
                type={showPassword2 ? "text" : "password"} // Show password if state is true
                id="confirmPassword"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                error={!passwordsMatch}
                helperText={!passwordsMatch ? "Passwords do not match" : ""}
                InputProps={{
                  // Add IconButton to toggle visibility
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword2(!showPassword2)}
                        edge="end"
                      >
                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {waitingResponse ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : null}
              {successAlert ? (
                <Alert
                  severity="success"
                  onClose={() => setSuccessAlert(false)}
                >
                  Password successfully changed
                </Alert>
              ) : null}
              {badRequestAlert ? (
                <Alert
                  severity="error"
                  onClose={() => setBadRequestAlert(false)}
                >
                  Error occurred while changing password
                </Alert>
              ) : null}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isButtonDisabled}
              >
                Change Password
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}
export default ChangePassword;
