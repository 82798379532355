
import AddIcon from '@mui/icons-material/PersonAdd';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import line from '../../assets/Images/divider-numU.svg'
import { Button } from "@mui/material";
import { Link } from 'react-router-dom';
import CardSuccesfulRegist from "./CardSuccesfulRegist";
import CardStartRegist from "./CardStartRegist";
import Amoount from "../Universals/Amount";
import * as React from 'react';
import arrow from "../../assets/Images/arrow-inicio.png"
import { Context } from '../../context/Context';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderNologo from '../Universals/HeaderNoLogo';



function NumberofUsers() {
    const navigate = useNavigate()
    const datacontext = useContext(Context);
    const txt = datacontext.myConfig.txt[datacontext.lenguage];
    useEffect(() => {
        if (datacontext.Data.Datos.detalles.length === 0) navigate("/TravelData");
    })
    let permisoNext = false;
    for (var i = 0; i < datacontext.Data.Datos.detalles.length; i++) {
        if (datacontext.Data.Datos.detalles[i].nombre.length < 2 ||
            datacontext.Data.Datos.detalles[i].pasaporte.length < 5 ||
            datacontext.Data.Datos.detalles[i].apellido.length < 2) {
            permisoNext = false;
            break;
        } else permisoNext = true;

    }


    return (<div className="workzone">
        <HeaderNologo navigate="/TravelData" />
        <h1>{txt[19]}</h1>
        {
            datacontext.Data.Datos.detalles.map((value, id) => {
                return (
                    value.nombre.length < 2 || value.pasaporte.length < 5 || value.apellido.length < 2 ?
                        <CardStartRegist id={id} key={id} />
                        : <CardSuccesfulRegist id={id} key={id} />
                )

            })
        }

        <img className="divider" src={line} ></img>

        <Amoount Data={datacontext.Data} />
        <div id="pagos">
            <ThemeProvider theme={datacontext.primaytheme}>
                <Button variant="text" startIcon={<AddIcon />} onClick={() => {
                    if(datacontext.Data.Datos.detalles.length<datacontext.limitvisitors)datacontext.UpdateUsers(null);
                }}>
                    {txt[20]}
                </Button>
            </ThemeProvider>
        </div>
        {
            permisoNext ? <Link style={{ textDecoration: 'none' }} to="/TotalPayment" className="stylebtn1" >{txt[18]} <img id="continue" src={arrow} alt="Visitax logo"></img></Link> : <></>
        }

    </div>)

} export default NumberofUsers;