import axios from "axios";

export const apivisitaxProd = axios.create({
  baseURL: process.env.REACT_APP_VISITAX_ENDPOINTPROD
})

export const apivisitaxDev = axios.create({
  baseURL: process.env.REACT_APP_VISITAX_ENDPOINTDEV
})




export const basicpostvisitax = async (url, data, callback, token = "", headers = null, sandbox = false) => {
  let config = null;

  if (headers != null) {
    config = {
      headers: {
        ...headers,
        'Authorization': 'Bearer ' + token,
        'Access-Control-Allow-Origin': '*'
      }
    }
  } else {
    config = {
      headers: {
        'Authorization': 'Bearer ' + token,
        'Access-Control-Allow-Origin': '*'
      }
    }
  }

  if (sandbox) {
    await apivisitaxDev.post(url, data, config)
      .then(respuesta => {
        callback(respuesta);
      }).catch(err => callback(err));
  } else {
    await apivisitaxProd.post(url, data, config)
      .then(respuesta => {
        callback(respuesta);
      }).catch(err => callback(err));
  }



}

export const basicgetvisitax = async (url, callback, token = "", sandbox = false) => {
  let config = {
    headers: {
      'Authorization': 'Bearer ' + token,
      'Access-Control-Allow-Origin': '*'
    }
  }


  if (sandbox) {
    await apivisitaxDev.get(url, config)
      .then(respuesta => {
        callback(respuesta);
      }).catch(err => callback(err));
  } else {
    await apivisitaxProd.get(url, config)
      .then(respuesta => {
        callback(respuesta);
      }).catch(err => callback(err));
  }



}

export const basicputvisitax = async (url, data, callback, token = "", sandbox = false) => {
  let config = {
    headers: {
      'Authorization': 'Bearer ' + token,
      'Access-Control-Allow-Origin': '*'
    }
  }

  if (sandbox) {
    await apivisitaxDev.put(url, data, config)
      .then(respuesta => {
        callback(respuesta);
      }).catch(err => callback(err));
  } else {
    await apivisitaxProd.put(url, data, config)
      .then(respuesta => {
        callback(respuesta);
      }).catch(err => callback(err));
  }




}