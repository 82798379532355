import React, { useState, useContext } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import InfoIcon from '@mui/icons-material/Info';
import HomeIcon from '@mui/icons-material/Home';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ArticleIcon from '@mui/icons-material/Article';
import { ThemeProvider } from '@mui/material/styles';
import { Context } from '../../../context/Context';
import QRVisitax from "../../../assets/Images/VisitaxQR.jpg";
import Infography from "../../../assets/Images/Infografia.jpg";
import Article51ES from "../../../assets/Images/Articulo51ES.png";
import Article51EN from "../../../assets/Images/Articulo51ENG.png";
import { Box, colors } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';

const IconNavigation = () => {
    const [value, setValue] = useState(0);
    const datacontext = useContext(Context);
    const languaje = datacontext.lenguage;

    const renderContent = () => {
        switch (value) {
            case 1:
                return <InfographyContent />;
            case 2:
                return <QRContent />;
            case 3:
                return <Article51 />;
            default:
                return null; 
        }
    };

    const QRContent = () => (
        <Box sx={{ position: 'fixed', top: 0, left: 0, padding: 0, width: '100vw', height: '100vh', bgcolor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
            <img
                src={QRVisitax}
                alt="QR"
                style={{ width: '100%', maxHeight: '100vh', objectFit: 'contain' }}
            />
        </Box>
    );

    const InfographyContent = () => (
        <Box sx={{ position: 'fixed', top: 0, left: 0, padding: 0, width: '100vw', height: '100vh', bgcolor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
            <img
                src={Infography}
                alt="Infography"
                style={{ width: '100%', maxHeight: '100vh', objectFit: 'contain' }}
            />
        </Box>
    );

    const Article51 = () => {
        const imgSource = languaje == 0 ? Article51EN : Article51ES;
        const altText = languaje == 0 ? "Article 51" : "Articulo 51";
        const linkText = languaje == 0 ? "See full article 51" : "Ver artículo completo"
        
        return (
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Box sx={{ position: 'fixed', top: 0, left: 0, padding: 0, width: '100vw', height: '100vh', bgcolor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', zIndex: 10 }}>
                    <img
                        src={imgSource}
                        alt={altText}
                        style={{ width: '100%', maxHeight: '100vh', objectFit: 'contain' }}
                    />
                </Box>
                <Box sx={{ position: 'fixed', bottom: '160px', left: '700px', zIndex: 11}}>
                    <a href="https://documentos.congresoqroo.gob.mx/leyes/L195-XVII-20221223-L1720221223029.pdf" target='_blank'>{linkText}</a>
                </Box>
            </Box>
        );
    };

    return (
        <ThemeProvider theme={datacontext.primaytheme}>
            <Box sx={{ display: 'flex', padding: 0 }}>
                <Box sx={{ position: 'relative', padding: 0 }}>
                    {renderContent()}
                </Box>
                <BottomNavigation
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    showLabels
                    sx={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 20 }}
                >
                    <BottomNavigationAction label="Home" icon={<HomeIcon />} />
                    <BottomNavigationAction label="Infografía" icon={<InfoIcon />} />
                    <BottomNavigationAction label="QR" icon={<QrCodeIcon />} />
                    <BottomNavigationAction label="Article 51" icon={<ArticleIcon />} />
                </BottomNavigation>
            </Box>
        </ThemeProvider>
    );
};

export default IconNavigation;
