import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import SettingsIcon from "@mui/icons-material/Settings";
import List from "@mui/material/List";
import {
  DashboardPermisions,
  AnalyticsPermisions,
  ReportsPermisions,
  UsersPermisions,
  ConfigurationsPermisions,
  AsesorPermisions,
} from "../../permissions/permissions";
import { Context } from "../../context/Context";
import { useContext } from "react";
function MainList(props) {
  const datacontext = useContext(Context);
  const DashBoardClick = () => {
    props.setActualView("Tablero principal");
  };

  const UsersClick = () => {
    props.setActualView("Usuarios");
  };
  const ReportsClick = () => {
    props.setActualView("Reportes");
  };
  const ConfigurationsClick = () => {
    props.setActualView("Configuraciones");
  };
  const AnalyticsClick = () => {
    props.setActualView("Analiticos");
  };
  const AsesorDashboardClick = () => {
    props.setActualView("Asesor");
  };

  const mainListItems = (
    <React.Fragment>
      {DashboardPermisions(datacontext.LoggedUser, datacontext.Sandbox) && (
        <ListItemButton onClick={DashBoardClick}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Tablero principal" />
        </ListItemButton>
      )}
      {AnalyticsPermisions(datacontext.LoggedUser, datacontext.Sandbox) && (
        <ListItemButton onClick={AnalyticsClick}>
          <ListItemIcon>
            <TroubleshootIcon />
          </ListItemIcon>
          <ListItemText primary="Analiticos" />
        </ListItemButton>
      )}
      {ReportsPermisions(datacontext.LoggedUser, datacontext.Sandbox) && (
        <ListItemButton onClick={ReportsClick}>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Reportes" />
        </ListItemButton>
      )}
      {UsersPermisions(datacontext.LoggedUser, datacontext.Sandbox) && (
        <ListItemButton onClick={UsersClick}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Usuarios" />
        </ListItemButton>
      )}
      {ConfigurationsPermisions(datacontext.LoggedUser,datacontext.Sandbox) && (
        <ListItemButton onClick={ConfigurationsClick}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Configuraciones" />
        </ListItemButton>
      )}
      {AsesorPermisions(datacontext.LoggedUser,datacontext.Sandbox) && (
        <ListItemButton onClick={AsesorDashboardClick}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Asesor" />
        </ListItemButton>
      )}
    </React.Fragment>
  );
  return <List component="nav">{mainListItems}</List>;
}
export default MainList;
