import PouchDB from "pouchdb";

const dbData = new PouchDB("VisitaxData");
export default dbData;
const defaultdbId = "dataPayment";
// Función para crear un documento
async function createDocument(doc, docId) {
  const doctosave = docId
    ? {
        ...doc,
        _id: docId,
      }
    : doc;

  try {
    const response = await dbData.put(doctosave);
    //console.log('Documento creado exitosamente:', response);
    return response;
  } catch (err) {
    console.error("Error creando documento:", err);
    return null;
  }
}

// Función para leer todos los documentos
async function readDocuments() {
  try {
    const allDocs = await dbData.allDocs({ include_docs: true });
    const docs = allDocs.rows.map((row) => row.doc);
    //console.log('Documentos leídos:', docs);
    return docs;
  } catch (err) {
    console.error("Error leyendo documentos:", err);
    return null;
  }
}

// Función para leer un documento por ID
async function readDocumentById(docId = defaultdbId) {
  try {
    const doc = await dbData.get(docId);
    //console.log('Documento leído:', doc);
    return doc;
  } catch (err) {
    console.error(`Error leyendo documento con ID ${docId}:`, err);
    return null;
  }
}

// Función para actualizar un documento
async function updateDocument(doc) {
  try {
    const response = await dbData.put(doc);
    //console.log('Documento actualizado exitosamente:', response);
    return response;
  } catch (err) {
    console.error("Error actualizando documento:", err);
    return null;
  }
}

async function updateDocumentById(docId, updatedFields) {
  try {
    const doc = await dbData.get(docId);
    const updatedDoc = { ...doc, ...updatedFields };
    const response = await dbData.put(updatedDoc);
    //console.log('Documento actualizado exitosamente:', response);
    return response;
  } catch (err) {
    console.error(`Error actualizando documento con ID ${docId}:`, err);
    return null;
  }
}

// Función para eliminar un documento
async function deleteDocument(docId = defaultdbId) {
  try {
    const doc = await dbData.get(docId);
    const response = await dbData.remove(doc);
    //console.log('Documento eliminado exitosamente:', response);
    return response;
  } catch (err) {
    console.error("Error eliminando documento:", err);
    return null;
  }
}

// Exportar las funciones CRUD
export {
  createDocument,
  readDocuments,
  readDocumentById,
  updateDocument,
  deleteDocument,
  updateDocumentById,
};
