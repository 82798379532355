
import CancelIcon from '@mui/icons-material/Cancel';

import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react';
import { Context } from '../../context/Context'

function CardSuccesfulRegist(props) {
    const datacontext = useContext(Context);
    const txt = datacontext.myConfig.txt[datacontext.lenguage];
    const User = txt[21] + (props.id + 1);
    const completName = datacontext.Data.Datos.detalles[props.id].nombre
        + " " + datacontext.Data.Datos.detalles[props.id].apellido;
    const navigate = useNavigate();


    return (
        <div className="cardzone">
            <div className="cardzone1" >
                <p className='p2'>{User}</p>
                <ThemeProvider theme={datacontext.primaytheme}>
             
                        <CancelIcon onClick={() => datacontext.UpdateUsers(props.id)} color='primary'/>
        
                </ThemeProvider>
            </div>
            <div className="cardzone1">

                <div className="zone1">
                    <h3>{completName}</h3>
                </div>
                <div className="zone2">
                    <p className='p3' onClick={() => {
                        datacontext.setID(props.id);
                        navigate('/DataVerification')
                      }
                    }>{txt[24]}</p>

                </div>
            </div>
        </div>)
}
export default CardSuccesfulRegist;