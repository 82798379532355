import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { Button } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import { useContext } from 'react';
import { Context } from '../../context/Context'



function ChooseFileManager() {
    const datacontext = useContext(Context);
    const navigate = useNavigate();
    const VisuallyHiddenInput = styled('input')({
         clip: 'rect(0 0 0 0)',
         clipPath: 'inset(50%)',
         height: 1,
         overflow: 'hidden',
         position: 'absolute',
         bottom: 0,
         left: 0,
         whiteSpace: 'nowrap',
         width: 1,
         capture:"camera",
         type:"file"
     });
 
 
     const onFileChange = (event) => {
         const file = event.target.files[0];
         datacontext.setFile(file);
         navigate('/CaptureVerification')
     };
     return (<>
         <Button onChange={onFileChange}  component="label"
             role={undefined}
             variant="text"
             tabIndex={-1}>
             <CameraAltIcon color="action" />
             <VisuallyHiddenInput type="file" accept='image/*' />
         </Button>
 
     </>)


} export default ChooseFileManager;

