import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { PersonSelectorPermision } from "../../../permissions/permissions";
import { useEffect, useState, useContext } from "react";
import { Context } from "../../../context/Context";
import { isSupport } from "../../../permissions/permissions";

const UserSelector = ({ onChange = () => {} }) => {
  const datacontext = useContext(Context);
  const [UsersList, setUsersList] = useState(null);
  const [selectedUser, setSelectedUser] = useState('');
  const userLogged = `${datacontext?.LoggedUser?.name} ${datacontext?.LoggedUser?.fatherLastName} ${datacontext?.LoggedUser?.motherLastName}`;

  useEffect(() => {
    const OnDataRecived = (response) => {
      if (response?.data?.data == null) setUsersList("Empty");
      else{
        const advisorslist = []
        response.data.data.map(user=>{
          if(user.userName.includes(".com")){
            advisorslist.push(user);
          }
        })
        setUsersList(advisorslist);

      } 
    };
    const OnDataFailed = (error) => {
      console.error("Error fetching users:", error);
      setUsersList("Empty");
    };
    if (UsersList == null) {
      datacontext.getUsers(OnDataRecived, OnDataFailed);
    }
  }, [UsersList]);

  useEffect(() => {
    if (datacontext?.LoggedUser) {
      if(isSupport(datacontext.LoggedUser, datacontext.Sandbox))datacontext.UpdateUserPayload(null);
      else datacontext.UpdateUserPayload(datacontext?.LoggedUser?.id);
    }
  }, [datacontext?.LoggedUser, UsersList]);

  const handleChangeUser = (event) => {
    const userId = event.target.value;
    setSelectedUser(userId);
    if(isSupport(datacontext.LoggedUser, datacontext.Sandbox)&&userId.includes(datacontext?.LoggedUser?.id))datacontext.UpdateUserPayload(null);
    else datacontext.UpdateUserPayload(userId);
    onChange(event.target.value);
  };

  return (
    <>
      {PersonSelectorPermision(datacontext.LoggedUser, datacontext.Sandbox) && (
        <FormControl sx={{ width: "280px" }}>
          <InputLabel id="user-select-label"></InputLabel>
          <Select
            labelId="user-select-label"
            value={selectedUser}
            onChange={handleChangeUser}
            displayEmpty
          >
            <MenuItem value="" disabled>
              {userLogged}
            </MenuItem>
            {Array.isArray(UsersList) &&
              UsersList.sort((a, b) => {
                const nameA = `${a?.name} ${a?.lastName || ""} ${
                  a?.fatherLastName
                } ${a?.motherLastName}`.toLowerCase();
                const nameB = `${b?.name} ${b?.lastName || ""} ${
                  b?.fatherLastName
                } ${b?.motherLastName}`.toLowerCase();
                return nameA.localeCompare(nameB);
              }).map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {`${user?.name} ${user?.lastName || ""} ${
                    user?.fatherLastName
                  } ${user?.motherLastName}`}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};
export default UserSelector;
