import NavigationIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import LenguageSelector from "../Universals/LenguageSelector";
import HomeIcon from '@mui/icons-material/Home';
import PaymentIcon from '@mui/icons-material/Payment';
import { Link } from 'react-router-dom';


function clearCacheAndRedirect(home) {
    // Borrar caché y datos de la base de datos del navegador
    if ('caches' in window) {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
    }
    if (home) window.location.href = '/PrincipalMenu';
    else window.location.href = '/TravelData';
}

function HeaderNologo(props) {
    return (
        <div className="backgroundhead2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Link style={{ textDecoration: 'none' }} to={props.navigate}>
                <IconButton aria-label="back" size="large">
                    {props.navigate === "/" ? <HomeIcon onClick={() => clearCacheAndRedirect(true)} /> : <NavigationIcon />}
                </IconButton>
            </Link>
            <LenguageSelector />

            <IconButton aria-label="payment" size="large" onClick={() => clearCacheAndRedirect(false)}>
                <PaymentIcon />
            </IconButton>

        </div>
    );
}

export default HeaderNologo;
