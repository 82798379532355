import { useEffect, useContext, useState } from "react";
import { Context } from "../../../context/Context";
import { useNavigate } from "react-router-dom";
import {
  ConfigureFirebase,
  authenticateWithEmailAndPassword,
} from "../../../context/firebase/firebaseconfig";
import { uploadFile } from "../../../context/firebase/storageCrud";
const useCompletePayment = ({ idPreTransaction, idTransaction }) => {
  const datacontext = useContext(Context);
  const txt = datacontext.myConfig.txt[datacontext.lenguage];
  const [makeRquest, setmakeRequest] = useState(false);
  const navigate = useNavigate();

  const manageResponse = (response) => {
    try {
      let jsonResponse = {};
      switch (response.status ? response.status : response.response.status) {
        case 200:
          jsonResponse = response?.data?.data?.requestTouristTaxes;
          if (jsonResponse) datacontext.UpdateData(jsonResponse[0]);
    
          OKResponsePay(
            response.data.data.data[0].comprobante,
            jsonResponse[0].user_assigned,
            jsonResponse[0].transactId
          );

          break;
        case 201:
          jsonResponse = response?.data?.data?.details?.body;
          if (jsonResponse) datacontext.UpdateData(jsonResponse);
          OKResponsePay(
            "Empty Comprobant:" + response.data.data.details.body,
            jsonResponse.user_assigned,
            jsonResponse.transactId
          );
          break;
        case 202:
          jsonResponse = response?.data?.data?.details?.body;
          if (jsonResponse) datacontext.UpdateData(jsonResponse);
          OKResponsePay(
            "Empty Comprobant:" + response.data.data.details.body,
            jsonResponse.user_assigned,
            jsonResponse.transactId
          );
          break;
        case 400:
          try {
            jsonResponse = response?.response?.data?.data?.details?.body;
            const messageerror =
            response?.response?.data?.data?.details?.messageerror;
            if (jsonResponse) datacontext.UpdateData(jsonResponse);
            FailResponsePay(messageerror);
          } catch {
            FailResponsePay("An unexpected error has ocurred");
          }

          break;
        default:
          FailResponsePay("Your payment record was declined");
          break;
      }
    } catch (e) {
      console.error("Error en manageResponse:", e);
      FailResponsePay();
    }
  };

  const OKResponsePay = (comprobante, idUser = "", idTran = "") => {
    const PhysicalRresponse = () => {
      navigate("/PaymentConfirmation?comprobant=" + comprobante);
    };
    try {
      const UpdateTransaction = (urlpicture) => {
        const UpdatePayload = {
          idTransaction: idTran,
          description: "Se sube imagen desde pasarela de pago",
          lotClosing: false,
          imageUrl: urlpicture,
          idUser: idUser,
        };
     
        datacontext.putPhysicalRecords(
          PhysicalRresponse,
          PhysicalRresponse,
          UpdatePayload
        );
      };

      const UploadVoucher = (storage) => {
        const filepath = datacontext.Sandbox
          ? "PhysicalVouchersSandox/" + idTran
          : "PhysicalVouchers/" + idTran;
        uploadFile(datacontext.voucherImage, filepath, storage)
          .then((urlpicture) => UpdateTransaction(urlpicture))
          .catch((e) => {
            console.log(e);
            PhysicalRresponse();
          });
      };

      const FirebaseConfigure = (res) => {
        try {
          const firebaseConfig = res.data.data.firebaseConfig;
          const firebaseUser = atob(res.data.data.userFirebase);
          const firebasePasword = atob(res.data.data.paswordFirebase);
          const { storage, auth } = ConfigureFirebase(firebaseConfig);
          authenticateWithEmailAndPassword(firebaseUser, firebasePasword, auth)
            .then(() => {
              UploadVoucher(storage);
            })
            .catch((e) => {
              console.log(e);
              PhysicalRresponse();
            });
        } catch (e) {
          console.log("Fallo al configurar firebase", e);
        }
      };

      datacontext.setComprobante(comprobante);
      if (datacontext.voucherImage) {
        datacontext.getFirebaseConfig(FirebaseConfigure, FirebaseConfigure);
      } else navigate("/PaymentConfirmation?comprobant=" + comprobante);
    } catch (error) {
      console.log(error);
      PhysicalRresponse();
    }
  };

  const FailResponsePay = (message = txt[77]) => {
    console.log(message);
    navigate("/TotalPayment?message=" + message);
  };

  useEffect(() => {
    if (idPreTransaction && idTransaction) {
      setmakeRequest(true);
    }
  }, []);

  useEffect(() => {
    if (makeRquest) {
      const url =
        "?idPretransaction=" + idPreTransaction + "&idOpenpay=" + idTransaction;
      datacontext.postCompletePreTransaction(
        manageResponse,
        manageResponse,
        url
      );
    }
  }, [makeRquest]);
  return {};
};
export default useCompletePayment;
