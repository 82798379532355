import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import ConfigurationsTable from './ConfigurationsTable';
import LenguageTable from './LenguageTable';
import { Context } from '../../../context/Context';
import { useContext, useEffect } from 'react';
import SimpleBackdrop from '../../Universals/BackDrop';
import { Container } from '@mui/material';
function Configurations() {
    const datacontext = useContext(Context);
    const [value, setValue] = React.useState('general');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        datacontext.getconfigurations();
    })


    return (
        <>
            <Box component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
               
                    overflow: 'auto',
                }}>
                <Toolbar />
                <Container>
                    {datacontext.myConfig.price == null ?
                        <SimpleBackdrop complete={false} /> :
                        <>

                            <Tabs
                                value={value}
                                onChange={handleChange}
                                textColor="primary"
                                indicatorColor="primary"
                                aria-label="secondary tabs example"
                            >
                                <Tab value="general" label="General" />
                                <Tab value="lenguages" label="Languages" />

                            </Tabs>
                            {value.includes('general') ? <ConfigurationsTable /> : <LenguageTable />}
                        </>}


                </Container>




            </Box>


        </>

    );


} export default Configurations;