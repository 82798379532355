import { useState, useContext } from "react";
import { Context } from "../../../context/Context";
import { TerminalSelectorPermisions } from "../../../permissions/permissions";
import { FormControl,InputLabel,Select,MenuItem } from "@mui/material";
const TerminalSelector = ({onChange = () => {} }) => {
  const datacontext = useContext(Context);
  const terminals = [
    "T2 Ambulatorio",
    "T2 Bandas",
    "T3 Bandas",
    "T3 Ambulatorio",
    "T3 Andenes",
    "T4 Ambulatorio",
    "T4 bandas",
    "N/A",
  ];
  const [terminal, setTerminal] = useState("");
  const loggedTerminal = `${datacontext?.Data?.Datos?.terminal}`;
  const handleChangeTerminal = (event) => {
    datacontext.UpdateTerminal(event.target.value);
    setTerminal(event.target.value);
    onChange(event.target.value);
  };

  return (<>
    {TerminalSelectorPermisions(datacontext?.LoggedUser, datacontext?.Sandbox) && (
                    <FormControl sx={{ width: '280px' }}>
                      <InputLabel id="terminal-select-label"></InputLabel>
                      <Select
                        labelId="terminal-select-label"
                        value={terminal}
                        onChange={handleChangeTerminal}
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          {loggedTerminal}
                        </MenuItem>
                        {terminals.map((term) => (
                          <MenuItem value={term} key={term}>
                            {term}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
  
  </>)
};
export default TerminalSelector;
