import * as React from 'react';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Link } from "react-router-dom";
import NavigationIcon from '@mui/icons-material/ArrowBack';
import satq from "../../assets/Images/SatQ.png"
import blanconegro from "../../assets/Images/blanconegrovisitax.png"
import divider from "../../assets/Images/divider-image.svg"
import LenguageSelector from "../Universals/LenguageSelector"
import { Context } from '../../context/Context';
import { useContext } from 'react';
import CircularProgress from '../Universals/CircularProgress';

function AskforRecipt() {
    const [waitingResponse, setWaitingResponse] = React.useState(false);
    const [recoverFailed, setRecoverFailed] = React.useState(false);
    const [recoverSucces, setRecoverSucces] = React.useState(false);
    const [PassportNumber, setPassportNumber] = React.useState("");
    const [Reciptlink, setReciptLink] = React.useState("");

    const datacontext = useContext(Context);
    const txt = datacontext.myConfig.txt[datacontext.lenguage];

    const OKResponse = (response) => {
        if (response.data.data === null) {
            FailResponse();
            return;
        }
            setRecoverFailed(false);
            setRecoverSucces(true);
        setReciptLink(response.data.data.data[0].comprobante);

    }
    const FailResponse = () => {
        setRecoverSucces(false);
        setRecoverFailed(true);
    }

    return (
        <>
            {waitingResponse ? <CircularProgress /> : <></>}
            <div className={waitingResponse ? "workzoneopacity" : "workzone"}>
                <div className="backgroundhead1">
                    <div className='backbutton'>

                        <Link style={{ textDecoration: 'none' }} to="/PrincipalMenu">
                            <IconButton aria-label="back" size="large">
                                <NavigationIcon />
                            </IconButton>
                        </Link>


                    </div>
                    <div className="logos">
                        <img className="satq" src={satq} alt="Visitax logo"></img>
                        <img id="divider" src={divider} alt="Visitax logo"></img>
                        <img className="blanconegrovisitax" src={blanconegro} alt="Visitax logo"></img>
                    </div>
                </div>
                <LenguageSelector />

                <h1>{txt[10]}</h1>
                <div className='recoverrecipt'>


                    <InputBase
                        disabled={waitingResponse}
                        sx={{ ml: 1, flex: 1 }}
                        placeholder={txt[55]}
                        inputProps={{ 'aria-label': 'enter your passport number' }}
                        value={PassportNumber}
                        onChange={(event) => { setPassportNumber(event.target.value) }}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => {
                        setWaitingResponse(true);
                        datacontext.getReceipt(PassportNumber, setWaitingResponse, OKResponse, FailResponse)
                    }}>
                        <SearchIcon />
                    </IconButton>


                </div>
                {
                    recoverFailed || recoverSucces ? <Alert severity={recoverSucces ? "success" : "error"}>
                        <AlertTitle>{recoverSucces ? txt[24] : txt[33]}</AlertTitle>
                        {recoverSucces ? txt[56] :
                            txt[57]}
                    </Alert> : <></>
                }
                {
                    recoverSucces ?
                        <Link style={{ textDecoration: 'none' }} to={Reciptlink} className="stylebtn1" >{txt[58]}</Link> : <></>
                }



            </div>
        </>
    )

} export default AskforRecipt;