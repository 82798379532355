
import { CreatePDF } from "./components/PDFReport/PDFReport"
import { useState,useEffect } from "react"
import SimpleBackdrop from "../../../../../../Universals/BackDrop"
import { Grid,Button } from "@mui/material"
import DownloadIcon from '@mui/icons-material/Download';
import { exportByPasport,exportByTransaction } from "./components/ExcelReport/ExcelReports"

const ClassicReports = ({
    Deposits,
    ServiceCharge,
    Taxes,
    Transactions,
    SellTimeChart,
    TransactionsList,
    NumberofPersons,
    TerminalObj,
    Dates,
    TransactionDetails,
    TableID })=>{

 
    const [report] = useState('pdf');
    const [fileUrlPDF, setFileUrlPDF] = useState(null);

    
    const downloadPDF = () => {
        const link = document.createElement('a');
        link.href = fileUrlPDF;
        link.download = 'Resumen'+Dates.StartDate+"" +Dates.EndDate+".pdf"; // Nombre del archivo descargado
        link.click(); // Simula el click en el enlace de descarga
    };

    useEffect(() => {
        setFileUrlPDF(null);
        if (report.includes('pdf') && Deposits && ServiceCharge && Taxes && Transactions && SellTimeChart && TransactionsList && NumberofPersons && TerminalObj && Dates) {
            CreatePDF({
                Deposits,
                ServiceCharge,
                Taxes,
                Transactions,
                SellTimeChart,
                TransactionsList,
                NumberofPersons,
                TerminalObj,
                Dates,
                TableID
            },
                setFileUrlPDF)

        }
    }, [Deposits,
        ServiceCharge,
        Taxes,
        Transactions,
        SellTimeChart,
        TransactionsList,
        NumberofPersons,
        TerminalObj,
        Dates]);

        return (<>

            <Grid container spacing={2}>
        
                <Grid item xs={12} md={4} lg={4}>
                    {fileUrlPDF &&TransactionsList.data ? <Button variant="outlined" startIcon={<DownloadIcon />} onClick={() => { exportByTransaction(TransactionsList.data,Dates) }}>
                        Exporta excel por transacción
                    </Button> : <></>}
                </Grid>
    
                <Grid item xs={12} md={4} lg={4}>
                    {fileUrlPDF &&TransactionDetails ? <Button variant="outlined" startIcon={<DownloadIcon />} onClick={() => { exportByPasport(TransactionDetails,Dates) }}>
                        Exporta excel por pasaporte
                    </Button> :<></>}
                </Grid>
    
                <Grid item xs={12} md={4} lg={4}>
                    {fileUrlPDF ? <Button variant="outlined" startIcon={<DownloadIcon />}  onClick={downloadPDF}>
                        Descarga PDF
                    </Button> : <></>}
                </Grid>
    
                <Grid item xs={12} md={12} lg={12}>
                    {fileUrlPDF ? <iframe src={fileUrlPDF} width="100%" height="650px" /> : <SimpleBackdrop complete={false} />}
                </Grid>
    
            </Grid>
        </>);
    

}
export default ClassicReports