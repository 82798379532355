import React, { useState } from "react";
import CameraViewer from "../../IndentityVirification/CameraViewer";
import { Modal, Box } from "@mui/material";

// Estilos para el Modal
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  padding: 4,
};

const CameraModal = ({ open, onClose, onImageTaken }) => {
  // Función para manejar la toma de la imagen
  const handleImageTaken = (file) => {
    onImageTaken(file); // Callback hacia el componente externo
    onClose(); // Cerrar el modal automáticamente
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <CameraViewer onImageTaken={handleImageTaken} />
      </Box>
    </Modal>
  );
};

export default CameraModal;
