import { Paper, Typography } from "@mui/material";

const Title = ({ title = "" }) => {
    return (
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
            {title}
        </Typography>
    );
};

const PrincipalValue = ({ value = "" }) => {
    return (
        <Typography component="p" variant="h4">
            {value}
        </Typography>
    );
};

const SecundaryValue = ({ value = "" }) => {
    return (
        <Typography color="text.secondary" sx={{ flex: 1 }}>
            {value}
        </Typography>
    );
};

const InfoCard = ({ element = { Title: "", Value: "", SecundaryValue: "" } }) => {
    return (
        <Paper
            sx={{
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                height: 180,
            }}
        >
            <Title title={element.Title} />
            <PrincipalValue value={element.Value} />
            <SecundaryValue value={element.SecundaryValue} />
        </Paper>
    );
};

export default InfoCard;
