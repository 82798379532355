import Table from "../Table/Table";
import { Grid ,Button} from "@mui/material";
import PhysicalVoucherModal from "../PhysicalVoucherModal/PhysicalVoucherModal";
import ActionModal from "../../../../../../Universals/ActionModal";
import useVouchers from "./hooks/useVoucher";
const Vouchers = ({ contentdashboard }) => {
  const {
    vouucherdata,
    voucherstatus,
    openVoucherViewer,
    PayementsVouchers,
    PayementsVouchersCompleted,
    alltransactionsactive,
    Pagination,
    LotClouser,
    handleOpenVoucherPicture,
    handleVoucherModalSubmit,
    setOpenModalViewer,
    setvoucherstatus,
    setvoucherdata
  } = useVouchers({ contentdashboard }); // Usa el hook

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Table
            headers={PayementsVouchers.headers && PayementsVouchers.headers}
            content={PayementsVouchers.data}
            totalElements={PayementsVouchers.totalSum}
            omitKeys={PayementsVouchers.omitkeys}
            onPageChange={Pagination}
            title="Transacciones físicas sin imagen"
            standardPagination={alltransactionsactive}
            actionButtonTitle="Subir imagen"
            onActionButtonClick={handleOpenVoucherPicture}
          />
        </Grid>

        <Grid item xs={12}>
          <Table
            headers={
              PayementsVouchersCompleted.headers &&
              PayementsVouchersCompleted.headers
            }
            content={PayementsVouchersCompleted.data}
            totalElements={PayementsVouchersCompleted.totalSum}
            omitKeys={PayementsVouchersCompleted.omitkeys}
            onPageChange={Pagination}
            title="Transacciones físicas completadas"
            standardPagination={alltransactionsactive}
            actionButtonTitle="Actualizar imagen"
            onActionButtonClick={handleOpenVoucherPicture}
          />
        </Grid>


        <Grid item xs={12}>
          <Button onClick={()=>setOpenModalViewer(true)}>Nuevo cierre de lote</Button>
          <Table
            headers={
              LotClouser.headers &&
              LotClouser.headers
            }
            content={LotClouser.data}
            totalElements={LotClouser.totalSum}
            omitKeys={LotClouser.omitkeys}
            onPageChange={Pagination}
            title="Cierres de lote"
            standardPagination={alltransactionsactive}
            actionButtonTitle="Actualizar imagen"
            onActionButtonClick={handleOpenVoucherPicture}
          />
        </Grid>
      </Grid>

      <PhysicalVoucherModal
        open={openVoucherViewer}
        onClose={() => {setOpenModalViewer(false);setvoucherdata(null)}}
        onSubmit={handleVoucherModalSubmit}
        vouucherdata={vouucherdata}
      />
      <ActionModal
        open={voucherstatus !== null}
        onClose={() => setvoucherstatus(null)}
        text={
          voucherstatus
            ? "Voucher has updated successfully!"
            : "An error has occurred while udating picture"
        }
      />
    </>
  );
};
export default Vouchers;
