import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SaveIcon from '@mui/icons-material/Save';
import { Button } from '@mui/material';
import { Context } from '../../../context/Context';
import { useContext, useEffect } from 'react';
import { SaveAlertDialog,SuccesUploadAlertDialog} from '../../Universals/Dialogs';

import SimpleBackdrop from '../../Universals/BackDrop';


function createData(
    config

) {
    return {
        config
    };
}



function Row({ row, datacontext }) {

    const [openDialog, setOpenDialog] = React.useState(false);
    
    const [openDialogSucces, setOpenDialogSucces] = React.useState(false);

    const [savingchanges, setsavingchanges] = React.useState(false);

    const [open, setOpen] = React.useState(false);


    const [copyConfig, setCopyconfig] = React.useState(row.config)

    const OnDataSaved = (response) => {

        setsavingchanges(false);
        datacontext.getconfigurations(true);
        setOpenDialogSucces(true);

    }
    const OnDataFailed = (response) => {
        console.log(response);
        setsavingchanges(false);
    }

    useEffect(() => {
        if (savingchanges) datacontext.putConfigurations(OnDataSaved, OnDataFailed, copyConfig);

    },[savingchanges])

    return (
        <React.Fragment>
            {savingchanges ? <SimpleBackdrop configurations={true} /> : <>
                <SaveAlertDialog open={openDialog} setOpen={setOpenDialog} setsavingchanges={setsavingchanges} />
                <SuccesUploadAlertDialog open={openDialogSucces} setOpen={setOpenDialogSucces} />
                
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>
                    {datacontext.LoggedUser.rol.includes('Admin')?
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <ModeEditIcon />}
                        </IconButton>
                    
                    :<></>}
                    </TableCell>

                    <TableCell align="left">{row.config.concept}</TableCell>
                    <TableCell align="left">{row.config.configurationInfo}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>


                                <TextField
                                    fullWidth sx={{ m: 1 }}
                                    id="outlined-number"
                                    label={copyConfig.concept}
                                    type={row.config.concept.includes("Impuesto") || row.config.concept.includes("UMA") ? "number" : "text"}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={copyConfig.configurationInfo}
                                    onChange={(e) => setCopyconfig({
                                        ...copyConfig,
                                        configurationInfo: e.target.value
                                    })}
                                />
                                <Button
                                    size="small"
                                    color="secondary"
                                    onClick={() => setOpenDialog(true)}
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                >
                                    <span>Save</span>
                                </Button>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>}

        </React.Fragment>
    );
}


function ConfigurationsTable() {
    const datacontext = useContext(Context);

    const rows = [

    ];
    try {
        datacontext.myConfig.allconfigArray.map(value => {
            if (!value.concept.includes("Languages")) rows.push(createData(value));
        })
    } catch (e) {
        console.log(e);
    }

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Concept</TableCell>
                        <TableCell align="left">Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row,id) => (
                        <Row key={id} row={row} datacontext={datacontext} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

} export default ConfigurationsTable;