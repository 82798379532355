import React from 'react';
import 'animate.css';
import './ConstructionPage.css';
import Visitax from '../../assets/Images/visitax-2.svg'

const ConstructionPage = () => {
    return (
        <div className="bg">

            <div className="overlay">
           
                <img className="logovisitaxconst" src={Visitax}></img>
                <h2 id="SitioConstrut"className="animate__animated animate__backInDown">Site Under Construction</h2>
            </div>
        </div>
    );
};

export default ConstructionPage;