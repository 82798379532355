

import * as React from 'react';
import ChooseFileManager from '../IndentityVirification/ChooseFileManager';
import { Deconstruyetexto } from './ProcessOCR';
import CircularProgress from '../Universals/CircularProgress';
import FailDialog from './FialDialog';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../context/Context'
import HeaderNologo from '../Universals/HeaderNoLogo';
import { Button } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

function CaptureVerification() {

    const datacontext = useContext(Context);
    const txt = datacontext.myConfig.txt[datacontext.lenguage];
    const navigate = useNavigate();

    useEffect(() => {
        if (datacontext.Data.Datos.detalles.length === 0) navigate("/TravelData");
    })
    const [waitingResponse, setWaitingResponse] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const gotoconfirmation = (person) => {
        const Person = {
            ...datacontext.Data.Datos.detalles[datacontext.userid],
            nombre: person.name,
            apellido: person.lastName + " " + person.mothersLastName,
            pasaporte: person.passportNumber,
            nacionalidad:person.nationality
        }
        datacontext.UpdatePersonValue(Person);
        navigate("/DataVerification");
    }
    const validImage = () => {
        if (datacontext.Data.Datos.detalles.length === 0) return (<></>);
        return (<img id="passportview" src={URL.createObjectURL(datacontext.file)} ></img>)
    }
    const OKResponse = (response) => {
        Deconstruyetexto(response.data.data.document.text).then(person => gotoconfirmation(person))
            .catch((err) => { console.log(err) })
    }
    const FailResponse = () => {
        setOpen(true);
    }

    const sendPicture = () => {
        let document = "";
        let reader = new FileReader();
        reader.readAsDataURL(datacontext.file);
        reader.onload = function () {
            document = reader.result;
            const imageData = {
                rawDocument: {
                    content: document.toString('base64').slice(23, document.toString('base64').length),
                    mimeType: 'image/jpeg',
                },
            };
            setWaitingResponse(true);
            datacontext.getOCR(setWaitingResponse, OKResponse, FailResponse, imageData);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    return (
        <>
            {waitingResponse ? <CircularProgress /> : <></>}

            <div className={waitingResponse ? "workzoneopacity" : "workzone"}>
                <HeaderNologo navigate="/NumberofUsers" />
                <h1>{txt[25]}</h1>
                <h3>{txt[21] + (datacontext.userid + 1)}</h3>
                <div className="pictureverification">
                    <h4>{txt[29]}</h4>
                    <p>{txt[30]}
                    </p>
                    {validImage()}

                </div>
                {datacontext.isIOS() ? <button style={{ textDecoration: 'none' }} className="stylebtn4" ><ChooseFileManager />{txt[31]}</button> : 
                <div style={{ textDecoration: 'none' }} to="/CaptureVerification" className="stylebtn4" onClick={() => { navigate("/IdentityVerifications") }} >
                    <Button >
                        <CameraAltIcon color="action" />
                    </Button>    {txt[31]}</div>}
                <button onClick={sendPicture} className="stylebtn1" >{txt[18]}</button>
            </div>
            <FailDialog open={open} setOpen={setOpen} />


        </>
    )
} export default CaptureVerification;