import * as React from "react";
import visitaxlogo from "../../assets/Images/LogoVisitaxAvionColor.png";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Context } from "../../context/Context";
import { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
// TODO remove, this demo shouldn't need to reset the theme.

function ResetPassword() {
  const datacontext = useContext(Context);
  const [username, setEmail] = useState("");
  const [waitingResponse, setWaitingResponse] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [badRequestAlert, setBadRequestAlert] = useState(false);
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [validations, setValidations] = useState({ email: false });
  const [errors, setErrors] = useState({ email: false });
  const [timer, setTimer] = useState(0);

  const onEmailChange = (event) => {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (event.target.value.match(validRegex)) {
      setValidations({ ...validations, email: true });
    } else {
      setValidations({ ...validations, email: false });
    }
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validations.email) {
      setErrors({ email: false });
      setWaitingResponse(true);
      setIsButtonDisabled(true); // Deshabilitar el botón al enviar
      setTimer(60); // Iniciar el temporizador
      datacontext.postResetPassword(
        handleEmailChange,
        handleEmailFail,
        username
      );
    } else {
      setErrors({ email: true });
    }
  };

  const handleEmailChange = (event) => {
    setSuccessAlert(true);
    setWaitingResponse(false);
    setTimeout(() => {
      setIsButtonDisabled(false); // Habilitar el botón después de 1 minuto
      setTimer(0); // Reiniciar el temporizador
    }, 60000);
  };

  const handleEmailFail = (error) => {
    setBadRequestAlert(true);
    setWaitingResponse(false);
    setIsButtonDisabled(false); // Habilitar el botón inmediatamente si hay un error
    setTimer(0); // Reiniciar el temporizador
  };

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  return (
    <div className="workzone">
      <div className="backgroundhead1"></div>
      <ThemeProvider theme={datacontext.primaytheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img className="visitaxlogo" src={visitaxlogo} alt="Visitax logo" />
            <Typography component="h1" variant="h5">
              Change Password
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                error={errors.email}
                autoFocus
                value={username}
                onChange={onEmailChange}
              />
              {waitingResponse && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <CircularProgress />
                </div>
              )}
              {successAlert && (
                <Alert
                  severity="success"
                  onClose={() => setSuccessAlert(false)}
                >
                  The email was sent successfully
                </Alert>
              )}
              {badRequestAlert && (
                <Alert
                  severity="error"
                  onClose={() => setBadRequestAlert(false)}
                >
                  Error occurred while sending email
                </Alert>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isButtonDisabled} // Deshabilitar el botón basado en el estado
              >
                {isButtonDisabled ? `Wait ${timer} seconds to re send` : 'Send Email'}
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}
export default ResetPassword;
