

import { ThemeProvider } from "styled-components";
import { CssBaseline } from "@mui/material";
import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Context } from "../../context/Context";
import { useContext } from "react";
import Typography from "@mui/material/Typography";
import { useEffect,useState } from "react";
const PrivacyNotice =()=>{
    const datacontext=useContext(Context)

    const [termsText, setTermsText] = useState('');

    useEffect(() => {
            fetch('/privacity.txt')
                .then(response => response.text())
                .then(text => setTermsText(text));
    }, []);

    return (
        <div className="workzone"style={{ backgroundColor: datacontext.primaytheme.palette.background.default }}>
            <div className="backgroundhead1">

            </div>
            <ThemeProvider theme={datacontext.primaytheme}>
                <Container component="main" sx={{paddingBottom:'20px'}} >
                    <CssBaseline />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign:'center',
                            backgroundColor: 'inherit',
                        }}
                    >
            
                        <Typography gutterBottom style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: termsText }} />
                       
                    </Box>
              
                </Container>
            </ThemeProvider>
            <div className="backgroundhead1">
        </div>
        </div>

    );

}
export default PrivacyNotice
