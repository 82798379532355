import CircularProgres from '@mui/material/CircularProgress';

function CircularProgress() {
    return (
        <div className="CircularProgress">
            <CircularProgres disableShrink sx={{
                color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
                animationDuration: '550ms',
                position: 'relative',
                left: -140,
            }}
                size={300}
                thickness={2} />
        </div>
    )

} export default CircularProgress