import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Context } from '../../../../../../../../context/Context';
import { useContext, useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { Paper, Typography } from '@mui/material';

const Title = ({ title = "" }) => {
    return (
        <Typography
            component="h2"
            variant="h6"
            color="primary"
            align="center"
            gutterBottom
        >
            {title}
        </Typography>
    );
};

function BarChar({ data, title = "title", height = 400 }) { // Añadimos height como prop con valor por defecto
    const datacontext = useContext(Context);
    const [xAxis, setXaxis] = useState([]);
    const [yAxis, setYaxis] = useState([]);

    useEffect(() => {
        const keys = Object.keys(data);
        const values = Object.values(data);

        setXaxis(keys);
        setYaxis(values);
    }, [data]);

    return (
        <ThemeProvider theme={datacontext.primaytheme}>
            <Paper>
                <Title title={title} />
                <BarChart
                    height={height} // Asignamos la altura aquí
                    xAxis={[{ scaleType: 'band', data: xAxis }]}
                    series={[{ data: yAxis, color: datacontext.primaytheme.palette.primary.main }]}
                />
            </Paper>

        </ThemeProvider>
    );
}

export default BarChar;
