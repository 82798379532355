import { useState, useContext } from "react";
import { Grid, Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import UploadExcelModal from "./components/UploadExcelModal/UploadExcelModal";
import { Context } from "../../../../../../../context/Context";
import ActionModal from "../../../../../../Universals/ActionModal";
import SimpleBackdrop from "../../../../../../Universals/BackDrop";
import { exportReport } from "./components/ExcelReport/ExcelReport";
import { getCurrentDate, month, year } from "../../../../../../../data/Datos";

const OpenPayReports = ({ filter, specificfilter }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const datacontext = useContext(Context);
  const [requestUpdateExcel, setRrequestUpdateExcel] = useState({
    loading: false,
    success: null,
    message: "",
  });
  const [requestReport, setRrequestReport] = useState({
    loading: false,
    success: null,
    message: "",
  });
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCloseActionModal = () => {
    setRrequestUpdateExcel((prev) => ({
      loading: false,
      success: null,
      message: "",
    }));
    setRrequestReport((prev) => ({
      loading: false,
      success: null,
      message: "",
    }));
  };
  const OnExcelUpdated = (response) => {
    setRrequestUpdateExcel({
      loading: false,
      success: true,
      message: "Se ha actualizado la informacion de OpenPay correctamente!",
    });
  };
  const OnExcelFailed = (response) => {
    let erromessage = response.response.data.data.details.body;
    setRrequestUpdateExcel({
      loading: false,
      success: false,
      message: erromessage,
    });
  };
  const handleSubmitExcel = (file) => {
    console.log("Archivo seleccionado:", file);
    // Crear un objeto FormData y añadir el archivo
    const formData = new FormData();
    formData.append("Excel", file); // 'excelFile' es el nombre del campo que enviarás al backend

    setRrequestUpdateExcel({
      loading: true,
      success: null,
      message: "",
    });
    datacontext.postUploadOpenPayExcel(OnExcelUpdated, OnExcelFailed, formData);
  };

  const OnOpenPayReport = (response) => {
    exportReport(response.data.data);
    setRrequestReport({
      loading: false,
      success: true,
    });
  };
  const OnFailOpenPayReport = (response) => {
    console.log(response);
    setRrequestReport({
      loading: false,
      success: false,
    });
  };

  const handleOpenPayReport = () => {
    setRrequestReport({
      loading: true,
      success: null,
    });

    let complementaryurl = "";

    if (filter.includes("today")) {
      complementaryurl =
        "?StartDate=" +
        getCurrentDate() +
        " 00:00:00&EndDate=" +
        getCurrentDate() +
        " 23:59:59";
    } else if (filter.includes("month")) {
      complementaryurl =
        "?StartDate=" +
        +year() +
        "/" +
        month() +
        "/01" +
        " 00:00:00&EndDate=" +
        getCurrentDate() +
        " 23:59:59";
    } else if (filter.includes("year")) {
      complementaryurl =
        "?StartDate=" +
        year() +
        "/01/01" +
        " 00:00:00&EndDate=" +
        getCurrentDate() +
        " 23:59:59";
    } else {
      complementaryurl = specificfilter.url;
    }

    datacontext.getOpenPayReport(
      OnOpenPayReport,
      OnFailOpenPayReport,
      complementaryurl
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={handleOpenModal}
          >
            Actualiza información de OpenPay
          </Button>
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={handleOpenPayReport}
          >
            Descarga análisis de OpenPay
          </Button>
        </Grid>
      </Grid>
      {requestUpdateExcel.loading ||
        (requestReport.loading && <SimpleBackdrop complete={true} />)}
      <ActionModal
        open={requestUpdateExcel.success !== null}
        onClose={handleCloseActionModal}
        text={requestUpdateExcel.message}
      />

      <ActionModal
        open={requestReport.success !== null}
        onClose={handleCloseActionModal}
        text={
          requestReport?.success
            ? "Datos Obtenidos correctamente, descargando reporte...!"
            : "Ah ocurrido un error al obtener la información."
        }
      />

      {/* Modal para cargar archivo Excel */}
      <UploadExcelModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmitExcel}
        vouucherdata={null} // Puedes pasar cualquier dato adicional necesario aquí
      />
    </>
  );
};

export default OpenPayReports;
