import * as XLSX from 'xlsx';

export const exportReport = (data) => {
    let wb = XLSX.utils.book_new();

    // Definir encabezados
    let headers = [
        "ID", "Transacción ID", "Monto", "Autorización", "Fecha de creación",
        "Nombre del cliente", "Email del cliente", "Teléfono del cliente", 
        "Descripción", "Estado", "3D Secure"
    ];

    // Crear la matriz de datos con los encabezados
    let reportData = [headers];

    // Asegurarte que `data` es un arreglo
    if (Array.isArray(data)) {
        data.forEach((item, index) => {
            reportData.push([
                item.id,
                item.transactionid,
                item.amount,
                item.authorization,
                item.creationDate,
                item.customerName,
                item.customerEmail,
                item.customerPhone,
                item.description,
                item.status,
                item.use3dSecure ? 'Sí' : 'No'  // Convertir booleano a texto
            ]);
        });
    }

    let ws = XLSX.utils.aoa_to_sheet(reportData);

    // Ajustar el ancho de las columnas automáticamente
    const columnWidths = reportData[0].map((_, i) => ({
        wch: Math.max(...reportData.map(row => (row[i] ? row[i].toString().length : 10))) + 2
    }));
    ws['!cols'] = columnWidths;

    // Añadir filtros a los encabezados
    ws['!autofilter'] = { ref: ws['!ref'] };

    // Añadir la hoja al libro
    XLSX.utils.book_append_sheet(wb, ws, "Reporte");

    // Escribir el archivo
    XLSX.writeFile(wb, "OpenPayReport.xlsx");
};
