import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';

function preventDefault(event) {
  event.preventDefault();
}

export default function Deposits(props) {
  let valuesret = { title: "", value: "" };

  const Values = () => {
    let valuesreturned = {}
    switch (props.type) {
      case 1:
        valuesreturned = { title: "Deposits", value: "$" + props.data.totalSum }
        break;
      case 2:
        valuesreturned = { title: "Transactions", value: props.data.totalSum }
        break;
      case 3:
        valuesreturned = { title: "Persons", value: props.data.totalSum }
        break;
    }
    return valuesreturned;
  }
  try {
    valuesret = Values();
  } catch (e) { }

  return (
    <React.Fragment>
      <Title>{valuesret.title}</Title>
      <Typography component="p" variant="h4">
        {valuesret.value}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>

      </Typography>
    </React.Fragment>
  );
}