import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import * as React from 'react';



function FailDialog(props) {
    const navigate = useNavigate();
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const handleTryAgain = () => {
        props.setOpen(false);
        navigate("/IdentityVerifications");
    };
    const handleManualy = () => {
        props.setOpen(false);
        navigate("/DataVerification");
    };
    const handleClose = () => {
        props.setOpen(false);
      };

    return (<>
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{"Do you want to try again or continue manually?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    An error has occurred while reading your passport.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleTryAgain}>Try Again</Button>
                <Button onClick={handleManualy}>Complete Manually</Button>
            </DialogActions>
        </Dialog>

    </>)




} export default FailDialog