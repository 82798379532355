import React from 'react';
import { LineChart, axisClasses } from '@mui/x-charts';
import { useTheme } from '@mui/material/styles';
import { Paper, Typography } from '@mui/material';

const Title = ({ title = "" }) => {
    return (
        <Typography
            component="h2"
            variant="h6"
            color="primary"
            align="center" // Centra el título
            gutterBottom // Añade espacio debajo del título
        >
            {title}
        </Typography>
    );
};

const Description = ({ description = "" }) => {
    return (
        <Typography
            component="h2"
            variant="h6"
            color="primary"
            align="center" // Centra el título
            gutterBottom // Añade espacio debajo del título
        >
            {description}
        </Typography>
    );
};

const CustomLineChart = ({ title, description, xAxisLabel, yAxisLabel, data }) => {
    const theme = useTheme();

    return (
        <Paper sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 240,
        }}>
            {/* Título centrado */}
            {title && <Title title={title} />}

            {/* Gráfico de líneas */}

            <LineChart
                dataset={data} // Enviamos el dataset completo
                xAxis={[
                    {
                        scaleType: 'band', // Usamos 'band' para escalado adecuado
                        dataKey: 'time', // Key en el dataset para el eje x
                        label: xAxisLabel && xAxisLabel, // Label personalizado

                        labelStyle: {
                            ...theme.typography.body1,
                            fill: theme.palette.text.primary,
                        },
                        tickNumber: data.length > 5 ? 5 : data.length, // Ajustamos ticks según los datos disponibles
                        tickLabelStyle: theme.typography.body2, // Estilo de etiquetas de ticks
                    },
                ]}
                yAxis={[
                    {
                        label: yAxisLabel, // Label personalizado del eje Y
                        labelStyle: {
                            ...theme.typography.body1,
                            fill: theme.palette.text.primary,
                        },
                        tickLabelStyle: theme.typography.body2,
                        tickNumber: 5, // Número de ticks del eje Y
                    },
                ]}
                series={[
                    {
                        dataKey: 'amount', // Key en el dataset para el valor del eje Y
                        showMark: false, // Sin marcadores en los puntos
                        color: theme.palette.primary.light, // Color de la línea
                    },
                ]}
                sx={{
                    width: '100%',
                    height: '100%', // Ajuste del tamaño del gráfico
                    [`.${axisClasses.root} line`]: { stroke: theme.palette.text.secondary }, // Color de líneas de los ejes
                    [`.${axisClasses.root} text`]: { fill: theme.palette.text.secondary }, // Color del texto de las etiquetas
                    [`& .${axisClasses.left} .${axisClasses.label}`]: {
                        transform: 'translateX(-5px)', // Ajuste de la posición de la etiqueta Y
                    },
                }}
            />

            {/* Descripción debajo del gráfico */}
            {description && <Description title={description} />}

        </Paper>
    );
};

export default CustomLineChart;
