export const SandboxprofilesId = 
{ "Asesor": "3354688A-A119-40D9-9C27-577F7885B277", //0: Campo o Asesor
  "Supervisor":"94DF578F-CAF9-449A-B4FC-17BFF652319E", //1 : Operador o Supervisor
  "Administrador":"DDFA3BA3-78C3-4AD3-953C-695931125B1B", //2: Administrador
  "Soporte":"E8DB369A-2A08-4B0C-8C77-6534336B06B5", //3:Soporte
  "Asesor TF":"395A1F16-AF32-4830-A214-1CDFEA54DE9D", //4:Asesor TPV
  }


export const ProductionprofilesId = 
 { "Asesor":"3354688A-A119-40D9-9C27-577F7885B277", //0: Campo o Asesor
  "Supervisor":"94DF578F-CAF9-449A-B4FC-17BFF652319E", //1: Operador o Supervisor
  "Administrador":"DDFA3BA3-78C3-4AD3-953C-695931125B1B", //2: Administrador
  "Soporte":"13779DFE-2C57-4977-A6D8-F1F275295DF3", //3: Soporte
  "Asesor TF":"608A2A2F-41D1-4C8F-9C4E-B934D3806AF1", //4:Asesor TPV
  }

export const ListOfRolesKeys = [
  "Asesor",
  "Asesor TF",
  "Supervisor",
  "Administrador",
  "Soporte",
]

const validatePermissions = (profileid, permissions) => {
  let permisionfounded = false;
  permissions.map((value) => {
    try{
      if (value.includes(profileid?.toUpperCase())) {
        permisionfounded = true;
        return;
      }
    }catch(e){
      return permisionfounded;
    }
   
  });
  return permisionfounded;
};



///TotalPaymentPermissions

export const isSupport =(user, sandbox)=>{
  if (!user) return false;
  const permissions = sandbox
    ? [SandboxprofilesId["Administrador"], SandboxprofilesId["Soporte"]]
    : [ProductionprofilesId["Administrador"],ProductionprofilesId["Soporte"]];

  return validatePermissions(user.idRol, permissions);
}
export const TotalPaymentSelectorPermission = (user, sandbox) => {
  if (!user) return false;
  const permissions = sandbox
    ? [SandboxprofilesId["Supervisor"], SandboxprofilesId["Administrador"], SandboxprofilesId["Soporte"],SandboxprofilesId["Asesor TF"]]
    : [ProductionprofilesId["Supervisor"],ProductionprofilesId["Administrador"],ProductionprofilesId["Soporte"],ProductionprofilesId["Asesor TF"]];

  return validatePermissions(user.idRol, permissions);
};
export const CompleteTransactionPermission = (user, sandbox) => {
  if (!user) return false;
  const permissions = sandbox
    ? [SandboxprofilesId["Administrador"], SandboxprofilesId["Soporte"]]
    : [ProductionprofilesId["Administrador"], ProductionprofilesId["Soporte"]];

  return validatePermissions(user.idRol, permissions);
};
export const RegularPaymentPermission = (user, sandbox) => {
  if (!user) return false;
  const permissions = sandbox
    ? [SandboxprofilesId["Supervisor"], SandboxprofilesId["Administrador"],SandboxprofilesId["Asesor TF"]]
    : [ProductionprofilesId["Supervisor"], ProductionprofilesId["Administrador"],ProductionprofilesId["Asesor TF"]];

  return validatePermissions(user.idRol, permissions);
};

///AdministrationPermissions
export const AdministrationPermissions = (user, sandbox) => {
    if (!user) return false;
    const permissions = sandbox
      ? [SandboxprofilesId["Supervisor"], SandboxprofilesId["Administrador"],SandboxprofilesId["Soporte"],SandboxprofilesId["Asesor"],SandboxprofilesId["Asesor TF"]]
      : [ProductionprofilesId["Supervisor"], ProductionprofilesId["Administrador"],ProductionprofilesId["Soporte"],ProductionprofilesId["Asesor"],ProductionprofilesId["Asesor TF"]];
  
    return validatePermissions(user.idRol, permissions);
  };

export const DashboardPermisions = (user, sandbox) => {
  if (!user) return false;
  const permissions = sandbox
    ? [SandboxprofilesId["Supervisor"], SandboxprofilesId["Administrador"]]
    : [ProductionprofilesId["Supervisor"], ProductionprofilesId["Administrador"]];
  
  return validatePermissions(user.idRol, permissions);
};

export const AnalyticsPermisions = (user, sandbox) => {
  if (!user) return false;
  const permissions = sandbox
    ? [SandboxprofilesId["Supervisor"], SandboxprofilesId["Administrador"], SandboxprofilesId["Soporte"]]
    : [ProductionprofilesId["Supervisor"], ProductionprofilesId["Administrador"], ProductionprofilesId["Soporte"]];
    
  return validatePermissions(user.idRol, permissions);
};

export const ReportsPermisions = (user, sandbox) => {
  if (!user) return false;
  const permissions = sandbox
    ? [SandboxprofilesId["Supervisor"], SandboxprofilesId["Administrador"]]
    : [ProductionprofilesId["Supervisor"], ProductionprofilesId["Administrador"]];

  return validatePermissions(user.idRol, permissions);
};

export const UsersPermisions = (user, sandbox) => {
  if (!user) return false;
  const permissions = sandbox
    ? [SandboxprofilesId["Administrador"]]
    : [ProductionprofilesId["Administrador"]];

  return validatePermissions(user.idRol, permissions);
};

export const ConfigurationsPermisions = (user, sandbox) => {
  if (!user) return false;
  const permissions = sandbox
    ? [SandboxprofilesId["Administrador"]]
    : [ProductionprofilesId["Administrador"]];

  return validatePermissions(user.idRol, permissions);
};

export const TerminalSelectorPermisions = (user, sandbox) => {
  if (!user) return false;
  const permissions = sandbox
  ? [SandboxprofilesId["Supervisor"], SandboxprofilesId["Administrador"], SandboxprofilesId["Soporte"]]
  : [ProductionprofilesId["Supervisor"],ProductionprofilesId["Administrador"],ProductionprofilesId["Soporte"]];

return validatePermissions(user.idRol, permissions);
};

export const PersonSelectorPermision = (user, sandbox) => {
  if (!user) return false;
  const permissions = sandbox
  ? [SandboxprofilesId["Supervisor"], SandboxprofilesId["Administrador"], SandboxprofilesId["Soporte"]]
  : [ProductionprofilesId["Supervisor"],ProductionprofilesId["Administrador"],ProductionprofilesId["Soporte"]];

return validatePermissions(user.idRol, permissions);
};

export const AsesorPermisions = (user, sandbox) => {
  if (!user) return false;
  const permissions = sandbox
    ? [SandboxprofilesId["Asesor"],SandboxprofilesId["Asesor TF"],SandboxprofilesId["Administrador"]]
    : [ProductionprofilesId["Asesor"],ProductionprofilesId["Asesor TF"],ProductionprofilesId["Administrador"]];
    
  return validatePermissions(user.idRol, permissions);
};
