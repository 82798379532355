import * as React from 'react';
import { useState, useContext, useEffect } from "react";
import { Context } from "../../../context/Context";
import SimpleBackdrop from "../../Universals/BackDrop";
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import UsersTable from './UsersTable';
import RegistUser from './RegistUser';
import { Container } from '@mui/material';



function Users() {
    const datacontext = useContext(Context);
    const [data, setData] = useState("null");
    const [open, setOpen] = React.useState(false);


    const OnDataRecived = (response) => {
        if (response.data.data == null) setData("Empty");
        else setData(response.data.data)

    }
    const OnDataFailed = (response) => {
    }
    useEffect(() => {
        if (data == null) { datacontext.getUsers(OnDataRecived, OnDataFailed); console.log("Pidiendo Usuarios") };
    }, [data])
    
    useEffect(() => {
        setData(null);
    }, [])


    return (<>
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
            }}
        >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                {data == null ? <SimpleBackdrop /> : <>
                    <UsersTable data={data} setOpen={setOpen} setData={setData} />
                    <RegistUser open={open} setOpen={setOpen} setData={setData} />

                </>}
            </Container>

        </Box>
    </>)


} export default Users;