import LoadingPlanet from "../Universals/LoadingPlanet";

import { useLocation } from "react-router-dom";
import useCompletePayment from "./hooks/useCompletePayment";

const CompletePayment = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const idPreTransaction = queryParams.get("idpretransaction");
  const idTransaction = queryParams.get("id");
  useCompletePayment({ idPreTransaction, idTransaction });

  return (
    <>
      <LoadingPlanet />
    </>
  );
};

export default CompletePayment;
