import { readDocumentById,deleteDocument,createDocument,updateDocumentById } from "./pouchdbData";

////// Login
export const GetUserDataPouch = (datacontext) => {
    readDocumentById('logged_user').then(user => {
        if (user) {
            datacontext.setLoggedUser(user);
            datacontext.UpdateTerminal(user.Terminal);
        } 
    });

}
export const DeletUserData =(datacontext)=>{
    deleteDocument('logged_user').then(resp=>{
        if (resp){
            console.log("Usuario eliminado con éxito");
        }
    })
    datacontext.setLoggedUser(null);
}

export const SaveLoggedUser = (userDoc)=>{
    try {
        createDocument(userDoc,'logged_user').catch(updateDocumentById('logged_user', userDoc));
    } catch (err) {
        updateDocumentById('logged_user', userDoc);
    }
}

///////

