// storageCRUD.js
import { ref, uploadBytes, getDownloadURL, deleteObject,listAll } from "firebase/storage";


// Función para subir un archivo
export const uploadFile = async (file, filePath,storage) => {
  try {
    const storageRef = ref(storage, filePath);
    const snapshot = await uploadBytes(storageRef, file);
    return await getDownloadURL(snapshot.ref);
  } catch (error) {
    console.error("Error al subir el archivo:", error);
    throw error;
  }
};

// Función para descargar un archivo
export const downloadFile = async (filePath,storage) => {
  try {
    const storageRef = ref(storage, filePath);
    return await getDownloadURL(storageRef);
  } catch (error) {
    console.error("Error al descargar el archivo:", error);
    throw error;
  }
};

// Función para eliminar un archivo
export const deleteFile = async (filePath,storage) => {
  try {
    const storageRef = ref(storage, filePath);
    await deleteObject(storageRef);
    console.log("Archivo eliminado correctamente");
  } catch (error) {
    console.error("Error al eliminar el archivo:", error);
    throw error;
  }
};

// Ejemplo de actualización de archivo (sube el nuevo archivo con el mismo nombre, lo que sobrescribe el anterior)
export const updateFile = async ( file, filePath,storage) => {
  try {
    await deleteFile(filePath,storage); // Primero elimina el archivo existente
    return await uploadFile(file, filePath,storage); // Luego sube el nuevo archivo
  } catch (error) {
    console.error("Error al actualizar el archivo:", error);
    throw error;
  }
};

export const listFilesAndUrls = async (path,storage) => {
  try {
    const listRef = ref(storage, path);
    const res = await listAll(listRef);
    const urls = await Promise.all(
      res.items.map(async (itemRef) => {
        const url = await getDownloadURL(itemRef);
        return { name: itemRef.name, url };
      })
    );
    return urls; // Array de objetos {name, url}
  } catch (error) {
    console.error("Error al listar los archivos:", error);
    throw error;
  }
};