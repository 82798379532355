export async function Deconstruyetexto(result) {
  const person = {
    lastName: "",
    mothersLastName: "",
    name: "",
    passportNumber: "",
    nationality: ""
  }



  const extraeNombres = (Parrafo) => {

    Parrafo = Parrafo.slice(2, Parrafo.length);
    person.name = Parrafo.slice(0, Parrafo.indexOf('<'));
    Parrafo = Parrafo.slice(Parrafo.indexOf('<'), Parrafo.length);
    if (Parrafo.charAt(0).includes('<')) {
      while (!Parrafo.charAt(1).includes('<')) {
        Parrafo = Parrafo.slice(1, Parrafo.length);
        person.name = person.name + " " + Parrafo.slice(0, Parrafo.indexOf('<'));
        Parrafo = Parrafo.slice(Parrafo.indexOf('<'), Parrafo.length);
      }

    }
    return (Parrafo);
  }

  const validaciondeParrafo = (Parrafo) => {
    if (Parrafo.length == 90) {
      person.passportNumber = Parrafo.slice(45, 45 + 9).replace(/(\r\n|\n|\r|\s)/gm, '');
    }
    if (Parrafo.includes('P<')) {
      Parrafo = Parrafo.slice(Parrafo.indexOf('P<') + 2, Parrafo.length);
      person.nationality = Parrafo.slice(0, 3);
      person.lastName = Parrafo.slice(3, Parrafo.indexOf('<'));
      Parrafo = Parrafo.slice(Parrafo.indexOf('<'), Parrafo.length);
      if (Parrafo.charAt(1).includes('<')) {
        Parrafo = extraeNombres(Parrafo);
      } else {
        Parrafo = Parrafo.slice(1, Parrafo.length);
        person.mothersLastName = Parrafo.slice(0, Parrafo.indexOf('<'));
        Parrafo = Parrafo.slice(Parrafo.indexOf('<'), Parrafo.length);
        Parrafo = extraeNombres(Parrafo);
      }
      Parrafo = Parrafo.replaceAll("<", '');
      Parrafo = Parrafo.slice(1, 10);
      Parrafo = Parrafo.replace(/(\r\n|\n|\r)/gm, '');
      person.passportNumber = Parrafo;


    }
  }

  validaciondeParrafo(result);

  const limpiarTexto = (texto, maxLength) => {
    texto = texto.replace(/[^a-zA-Z\s]/g, '').trim();
    return texto.length > maxLength ? texto.slice(0, maxLength) : texto;
  };

  const validarPassportNumber = (passportNumber) => {
    passportNumber = passportNumber.replace(/\s+/g, '');
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    if (passportNumber.length >= 5 && passportNumber.length <= 13 && alphanumericRegex.test(passportNumber)) {
        return passportNumber;
    } else {
        return "";
    }
};


  // Limpiar y validar los campos
  person.lastName = limpiarTexto(person.lastName, 20).trim();
  person.name = limpiarTexto(person.name, 30).trim();
  person.mothersLastName = limpiarTexto(person.mothersLastName, 20).trim();
  person.passportNumber = validarPassportNumber(person.passportNumber).trim();

  return person;
}