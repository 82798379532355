import React, { useEffect, useState, useContext } from "react";
import { Link } from 'react-router-dom';
import { Button, Grid } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { Context } from '../../context/Context';
import { Datos } from '../../data/Datos';
import LenguageSelector from "../Universals/LenguageSelector";
import ConstructionPage from "./ConstructionPage";
import "../../assets/styles/general.css";
import arrow from "../../assets/Images/arrow-inicio.png";
import blanconegro from "../../assets/Images/blanconegrovisitax.png";

function Principal() {
   const datacontext = useContext(Context);
   const txt = datacontext.myConfig.txt[datacontext.lenguage];
   const [mantainment, setmanteiniment] = useState(0);
   const isLogin = datacontext.LoggedUser;
   
   
   useEffect(() => {
      if (datacontext.Data.Datos.detalles !== Datos.detalles) datacontext.ResetData();
   }, [datacontext.Data.Datos.detalles]);

   useEffect(() => {
      if (datacontext.myConfig.allconfigArray) {
         try {
            datacontext.myConfig.allconfigArray.map(value => {
               if (value.concept === "MaintenanceActive") {
                  setmanteiniment(Number(value.configurationInfo));
               }
            });
         } catch (e) {
            console.log(e);
         }
      }
   }, [datacontext.myConfig]);

   useEffect(() => {
      datacontext.getconfigurations(true);
   }, []);

   return (
      <>
         <div className="cancunbackground">
            {mantainment === 1 ? <ConstructionPage /> : (
               <>
                  <ThemeProvider theme={datacontext.primaytheme}>
                     <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                           <LenguageSelector />
                        </Grid>
                        {!isLogin ? (
                           <Grid item>
                              <Button variant="outlined" href="/Login">{txt[60]}</Button>
                           </Grid>
                        ) : ''}
                     </Grid>
                  </ThemeProvider>
                  <img className="logovisitax" src={blanconegro} alt="Visitax logo"></img>
                  <Link
                     style={{ textDecoration: 'none' }}
                     to="/PrincipalMenu"
                     className="stylebtn1"
                  >
                     {txt[1]} <img id="continue" src={arrow} alt="Visitax logo"></img>
                  </Link>
               </>
            )}
         </div>


      </>
   );
}

export default Principal;
