import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Context } from '../../../context/Context';
import { useContext, useEffect } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { SaveAlertDialog, SuccesUploadAlertDialog } from '../../Universals/Dialogs';

function createData(
    concept,
    value,
    text
) {
    return {
        concept,
        value,
        text
    };
}
function createDatatxt(
    id,
    text,
    rowtext
) {
    return {
        id,
        text,
        rowtext
    };
}

function TextRow({ row, datacontext,idrow }) {

    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [copytext, setCopyText] = React.useState(row.rowtext);


    const [openDialog, setOpenDialog] = React.useState(false);

    const [openDialogSucces, setOpenDialogSucces] = React.useState(false);

    const [savingchanges, setsavingchanges] = React.useState(false);
    function handleClick() {
        setLoading(true);
    }

    const OnDataSaved = (response) => {

        setsavingchanges(false);
        datacontext.getconfigurations(true);
        setOpenDialogSucces(true);

    }
    const OnDataFailed = (response) => {
        console.log(response);
        setsavingchanges(false);
    }

    useEffect(() => {
        if (savingchanges) {
            const copyconfigurations = { ...datacontext.myConfig.allconfigArray }
            const copyaltext = [...datacontext.myConfig.txt];
            copyaltext[idrow] = copytext;
            const datamodified = JSON.stringify({ languages: datacontext.myConfig.lenguages, flags: datacontext.myConfig.flags, txt: copyaltext });
            console.log(datamodified);
            datacontext.myConfig.allconfigArray.map(value => {
                if (value.concept.includes("Idiomas")) datacontext.putConfigurations(OnDataSaved, OnDataFailed,
                     {
                    ...value,
                    configurationInfo:datamodified
                });
            })
        }

    }, [savingchanges])



    return (
        <React.Fragment>
            <SaveAlertDialog open={openDialog} setOpen={setOpenDialog} setsavingchanges={setsavingchanges} />
            <SuccesUploadAlertDialog open={openDialogSucces} setOpen={setOpenDialogSucces} />
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    {datacontext.LoggedUser.rol.includes('Admin') ?
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? < KeyboardArrowUpIcon /> : <ModeEditIcon />}
                        </IconButton>
                        : <></>}

                </TableCell>
                <TableCell align="left">{row.id}</TableCell>
                <TableCell align="left">{row.text}</TableCell>

            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>


                            <TextField
                                fullWidth sx={{ m: 1 }}
                                id="outlined-number"
                                label={row.text}
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={copytext[(row.id).toString()]}
                                onChange={(e) => {
                                    const text = { ...copytext };
                                    text[(row.id).toString()] = e.target.value;
                                    setCopyText(text);
                                }}
                            />
                            <Button
                                size="small"
                                color="secondary"
                                onClick={() => setOpenDialog(true)}
                                startIcon={<SaveIcon />}
                                variant="contained"
                            >
                                <span>Save</span>
                            </Button>

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


function Row({ row, datacontext, idrow }) {

    const rowstxt = [];
    const createRows = () => {
        for (let id in row.text) {
            if (row.text.hasOwnProperty(id)) {
                rowstxt.push(createDatatxt(id, row.text[id], row.text))
            }
        }
    }
    createRows();
    const [open, setOpen] = React.useState(false);
    const src = `https://flagcdn.com/w20/${row.concept.toLowerCase()}.png`

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? < VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="left">{<img src={src}></img>}{row.concept}</TableCell>
                <TableCell align="left">{row.value}</TableCell>

            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>

                            <TableContainer component={Paper}>
                                <Table aria-label="collapsible table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rowstxt.map((row, id) => (
                                            <TextRow key={id} row={row} datacontext={datacontext} idrow={idrow} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}



function LenguageTable() {
    const rows = [];
    const datacontext = useContext(Context);
    const createRows = () => {
        datacontext.myConfig.flags.map((value, id) => {
            rows.push(createData(value, datacontext.myConfig.lenguages[id], datacontext.myConfig.txt[id]))
        });

    }
    createRows();
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />

                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, idrow) => (
                        <Row key={row.concept} row={row} datacontext={datacontext} idrow={idrow} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

} export default LenguageTable;