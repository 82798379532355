import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Title from './Title';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Button, TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Context } from '../../context/Context';
import SimpleBackdrop from '../Universals/BackDrop';
import { SuccesUploadAlertDialog } from '../Universals/Dialogs';

function createData(voucher, openpayKey, numberofRecords, datecreate, payment, amount, id, pasport = "", serviceCharge = 0) {
  return { voucher, openpayKey, numberofRecords, datecreate, payment, amount, id, pasport, serviceCharge };
}

function createData2(id, idUser, idTransaction, createdAt, createdBy) {
  return { id, idUser, idTransaction, createdAt, createdBy };
}

export default function Orders(props) {
  const datacontext = useContext(Context);
  const [request, setRequest] = React.useState({ state: false, id: "" });
  const [transactioncompleted, setTransactioncompleted] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const onOKPost = (respuesta) => {
    setRequest({ state: false, id: "" });
    setTransactioncompleted(true);
    props.setnewRequest(true);
  }

  const onFail = (respuesta) => {
    console.log(respuesta);
    setRequest({ state: false, id: "" });
  }

  useEffect(() => {
    if (request.state) datacontext.postCompleteTransaction(onOKPost, onFail, request.id);
  }, [request]);

  const handleChange = (event, value) => {
    props.setPage(value);
  };

  let empty = false;
  const rows = [];
  let pagintationcount = 10;

  try {
    const createRows = () => {
      if (props.type === 3) {
        props.data.alltransaction.forEach((value) => {
          rows.push(createData2(value.id, value.idUser, value.idTransaction, value.createdAt, value.createdBy));
          pagintationcount = Math.round(props.data.totalSum / props.data.alltransaction.length);
        });
      } else if (props.type === 2) {
        props.data.allPaymentRegister.forEach((value) => {
          rows.push(createData(value.voucher, value.openpayKey, value.numberofRecords, value.datecreate, value.payment, value.amount, value.id, value.pasport, value.serviceCharge));
        });
        pagintationcount = Math.round(props.data.totalSum / props.data.allPaymentRegister.length);
      } else {
        props.data.allPaymentRegister.forEach((value) => {
          rows.push(createData(value.voucher, value.openpayKey, value.numberofRecords, value.datecreate, value.payment, value.amount, value.id, value.pasport, value.serviceCharge));
        });
        pagintationcount = Math.round(props.data.totalSum / props.data.allPaymentRegister.length);
      }
    };

    createRows();
    empty = false;
  } catch (e) {
    console.log(e);
    empty = true;
  }

  const handleComplete = (Id) => {
    setRequest({ state: true, id: Id });
    
  };

  const filteredRows = rows.filter(row => {
    try {
      if (props.type === 1) {
        return (
          (row.voucher?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
          (row.openpayKey?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
          (row.datecreate?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
          row.numberofRecords?.toString().includes(searchTerm.toLowerCase()) ||
          row.amount?.toString().includes(searchTerm.toLowerCase()) ||
          row.serviceCharge?.toString().includes(searchTerm.toLowerCase())
        );
      } else if (props.type === 2) {
        return (
          (row.voucher?.toLowerCase() ?? '').includes(searchTerm) ||
          (row.openpayKey?.toLowerCase() ?? '').includes(searchTerm) ||
          (row.datecreate?.toLowerCase() ?? '').includes(searchTerm) ||
          row.numberofRecords?.toString().includes(searchTerm) ||
          row.pasport?.toString().includes(searchTerm) ||
          row.amount?.toString().includes(searchTerm)
        );
      } else if (props.type === 3) {
        return (
          (row.idTransaction?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
          (row.createdAt?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
          (row.createdBy?.toLowerCase() ?? '').includes(searchTerm.toLowerCase())
        );
      }
    } catch (e) {
      console.log(e);
    }
  });

  return (
    <>
      {empty ? (
        <></>
      ) : (
        <React.Fragment>
          {request.state ? (
            <SimpleBackdrop />
          ) : (
            <>
              <SuccesUploadAlertDialog
                open={transactioncompleted}
                setOpen={setTransactioncompleted}
              />
              <Title>{props.type === 1 ? "Recent Orders" : "Incidents"}</Title>
              <TextField
                label="Search"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ marginBottom: '20px' }}
              />
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {props.type < 3 ? (
                        <>
                          <TableCell>Voucher</TableCell>
                          <TableCell>Openpay Key</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Persons</TableCell>
                          <TableCell align="right">Sale Amount</TableCell>
                          {props.type === 2 ? <TableCell>Passport</TableCell> : <></>}
                          {props.type === 1 ? <TableCell>Service Charge</TableCell> : <></>}
                        </>
                      ) : (
                        <>
                          <TableCell>Transaction</TableCell>
                          <TableCell>Created At</TableCell>
                          <TableCell>Created By</TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows.map((row, id) => (
                      <TableRow key={id}>
                        {props.type < 3 ? (
                          <>
                            <TableCell>{row.voucher}</TableCell>
                            <TableCell>{row.openpayKey}</TableCell>
                            <TableCell>{row.datecreate}</TableCell>
                            <TableCell>{row.numberofRecords}</TableCell>
                            <TableCell align="right">{`$${row.amount}`}</TableCell>
                            {props.type === 1 ? <TableCell>{row.serviceCharge}</TableCell> : <></>}
                            {props.type === 2 ? (
                              <>
                                <TableCell>{row.pasport}</TableCell>
                                <TableCell align="center">
                                  <Button onClick={() => handleComplete(row.id)}>
                                    Complete transaction
                                  </Button>
                                </TableCell>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <>
                            <TableCell>{row.idTransaction}</TableCell>
                            <TableCell>{row.createdAt}</TableCell>
                            <TableCell>{row.createdBy}</TableCell>
                          </>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {props.type === 2 ? (
                <></>
              ) : (
                <>
                  <Stack spacing={2}>
                    <Pagination
                      count={pagintationcount}
                      color="primary"
                      page={props.page}
                      onChange={handleChange}
                    />
                  </Stack>
                </>
              )}
            </>
          )}
        </React.Fragment>
      )}
    </>
  );
}