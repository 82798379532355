import UseContentDashboard from "../hooks/UseContentDashboard";
import { useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import ClassicReports from "./components/ClassicReports/ClassicReports";
import OpenPayReports from "./components/OpenPayReports/OpenPayReports";
const Reports = ({ filter = "today", specificfilter = null }) => {
  const {
    Deposits,
    ServiceCharge,
    Taxes,
    Transactions,
    SellTimeChart,
    TransactionsList,
    NumberofPersons,
    TerminalObj,
    Dates,
    TransactionDetails,
    TableID,
  } = UseContentDashboard({ filter, specificfilter });

  const [value, setValue] = useState("generalreports");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <>
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab value="generalreports" label="Reportes generales" />
            <Tab value="openpay" label="Reportes de OpenPay" />
          </Tabs>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          {value === "generalreports" && (
            <ClassicReports
              Deposits ={Deposits}
              ServiceCharge={ServiceCharge}
              Taxes={Taxes}
              Transactions={Transactions}
              SellTimeChart={SellTimeChart}
              TransactionsList={TransactionsList}
              NumberofPersons={NumberofPersons}
              TerminalObj={TerminalObj}
              Dates={Dates}
              TransactionDetails={TransactionDetails}
              TableID={TableID}
            />
          )}
          {value === "openpay" && <OpenPayReports filter={filter} specificfilter={specificfilter}/>}
        </Box>
      </>
    </>
  );
};
export default Reports;
