import { Stack } from "@mui/material";
import CardNumber from "./components/CardNumer";
import Dateexpire from "./components/Dateexpire";
import CVV from "./components/CVV";
import { useState,useEffect } from "react";
import MasterCard from "../../../../assets/Images/Mastercard-logo.png";
import Visa from "../../../../assets/Images/Visa_Logo.png";
import AmericanExpress from "../../../../assets/Images/American_Express_logo.png";
const DigitalCardForm = ({
  paymentType = "",
  onCardChange = () => {},
  waitingResponse = false,
  ValidData=()=>{}
}) => {
  const cardSelector = () => {
    switch (cardtype) {
      case "visa":
        return <img className="visa" src={Visa} />;
      case "mastercard":
        return <img className="visa" src={MasterCard} />;
      case "amex":
        return <img className="visa" src={AmericanExpress} />;
      default:
        return <></>;
    }
  };
  const [cardtype, setCardType] = useState("Unknown");
  const [expireValidation, setexpireValidation] = useState(false);
  const [CvvValidation, setCvvValidation] = useState(false);
  const [cardData, setcardData] = useState({
     card_number: "",
     expiration_month: "",
     expiration_year: "",
     cvv2: "",
  },)

  useEffect(()=>{
     const cardValidation = cardtype !== "Unknown";
     if(cardValidation && expireValidation && CvvValidation){
          ValidData(true);
     }else{
          ValidData(false);
     }
  },[cardtype,expireValidation,CvvValidation])

  useEffect(()=>{
    onCardChange(cardData);
  },[cardData])





  return (
    <>
      {paymentType === "digital" && (
        <>
          <Stack direction="row" spacing={2}>
            <CardNumber
              setCardType={setCardType}
              waitingResponse={waitingResponse}
              onCardChange={(cardNumber)=>{setcardData(prev=>({...prev,card_number:cardNumber}))}}
            />
            <div className="divlogos">{cardSelector()}</div>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Dateexpire
              setexpireValidation={setexpireValidation}
              waitingResponse={waitingResponse}
              onDateChange={(month,year)=>{setcardData(prev=>({...prev,expiration_month:month,expiration_year:year}))}}
            />
            <CVV
              setCvvValidation={setCvvValidation}
              waitingResponse={waitingResponse}
              onCVVChange={(cvv)=>{setcardData(prev=>({...prev,cvv2:cvv}))}}
            />
          </Stack>
        </>
      )}
    </>
  );
};
export default DigitalCardForm;
