import { Modal,Typography,Button } from "@mui/material";
const ActionModal = ({text,open,onClose}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "16px",
          boxShadow: "0 3px 5px rgba(0,0,0,0.3)",
          borderRadius: "8px",
          maxWidth: "400px",
          textAlign: "center",
        }}
      >
        <Typography variant="h6">
         {text}
        </Typography>
        <Button
          onClick={onClose}
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};
export default ActionModal;
