import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Button } from '@mui/material';



import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';



function FilterDash(props) {
  const [FirstDate, setFirstDate] = React.useState(dayjs());
  const [LasDate, setLastDate] = React.useState(dayjs());
  const todayHanlder = () => {
    if (!props.filter.includes("Today")) {
      props.setFilter("Today");
    }
    props.setnewRequest(true);
    try {
      props.setRequestDeposits(true);
    } catch (e) { }
    try {
      props.setRequestTerminals(true);
    } catch (e) { }


  }
  const currentMonthhandler = () => {
    if (!props.filter.includes("Month")) {
      props.setFilter("Month");
    }
    props.setnewRequest(true);
    try {
      props.setRequestDeposits(true);
    } catch (e) { }
    try {
      props.setRequestTerminals(true);
    } catch (e) { }
  }
  const currentWeekhanlder = () => {
    if (!props.filter.includes("Year")) {
      props.setFilter("Year");
    }
    props.setnewRequest(true);
    try {
      props.setRequestDeposits(true);
    } catch (e) { }
    try {
      props.setRequestTerminals(true);
    } catch (e) { }
  }
  const setlectHandler = () => {
    if (!props.filter.includes("Select")) {
      props.setFilter("Select");
    }

  }
  const OnFirstDateChange = (date) => {
    setFirstDate(date);
  }
  const OnDateLastDateChange = (date) => {
    setLastDate(date);

  }
  const onLookClick = () => {

    const FirstMonth = FirstDate.$M < 9 ? "0" + (FirstDate.$M + 1) : FirstDate.$M + 1;
    const FirstDay = FirstDate.$D < 9 ? "0" + (FirstDate.$D) : FirstDate.$D;
    const FirstYear = FirstDate.$y;

    const LastMonth = LasDate.$M < 9 ? "0" + (LasDate.$M + 1) : LasDate.$M + 1;
    const LastDay = LasDate.$D < 9 ? "0" + (LasDate.$D) : LasDate.$D;
    const LastYear = LasDate.$y;
    const url = "?StartDate=" + FirstYear + "/" + FirstMonth + "/" + FirstDay + " 00:00:00&EndDate=" + LastYear + "/" + LastMonth + "/" + LastDay + " 23:59:59&PageNumber=" + props.page + "&PageSize=" + props.registPerPage;
    const url2 = "?StartDate=" + FirstYear + "/" + FirstMonth + "/" + FirstDay + " 00:00:00&EndDate=" + LastYear + "/" + LastMonth + "/" + LastDay + " 23:59:59&filter=D";
    const url3 = "?StartDate=" + FirstYear + "/" + FirstMonth + "/" + FirstDay + " 00:00:00&EndDate=" + LastYear + "/" + LastMonth + "/" + LastDay + " 23:59:59&Terminals=1";
    let FiltersData = [...props.FiltersData];

    FiltersData[3] = url;
 
    props.setFilterData(FiltersData);

    props.setnewRequest(true);
    try {
      let FiltersData2 = [...props.FiltersData2];
      FiltersData2[3] = url2;
      props.setFilterData2(FiltersData2);
      props.setRequestDeposits(true);
    } catch (e) { }
    try {
      let FiltersData3 = [...props.FiltersData3];
      FiltersData3[3] = url3;
      props.setFilterData3(FiltersData3);
      props.setRequestTerminals(true);
    } catch (e) { }
  }

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Select a Filter</FormLabel>
      <Accordion expanded={props.filter.includes("Select")}>
        <AccordionSummary
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <FormControlLabel
            value="top"
            control={<Checkbox />}
            label="Select Range"
            labelPlacement="start"
            checked={props.filter.includes("Select")}
            onChange={setlectHandler}
          />
        </AccordionSummary>
        <AccordionDetails>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker', 'DatePicker']}>
              <DatePicker label="First Date" defaultValue={dayjs()} onChange={OnFirstDateChange} value={FirstDate} />
              <DatePicker
                label="Final Date" defaultValue={dayjs()} onChange={OnDateLastDateChange} value={LasDate}
              />
            </DemoContainer>
          </LocalizationProvider>


        </AccordionDetails>
        <AccordionActions>
          <Button onClick={onLookClick}>Look</Button>
        </AccordionActions>
      </Accordion>
      <FormGroup aria-label="position" row>

        <FormControlLabel
          value="top"
          control={<Checkbox />}
          label="Today"
          labelPlacement="start"
          checked={props.filter.includes("Today")}
          onChange={todayHanlder}
        />
        <FormControlLabel
          value="top"
          control={<Checkbox />}
          label="Current Month"
          labelPlacement="start"
          checked={props.filter.includes("Month")}
          onChange={currentMonthhandler}
        />
        <FormControlLabel
          value="top"
          control={<Checkbox />}
          label="Current Year"
          labelPlacement="start"
          checked={props.filter.includes("Year")}
          onChange={currentWeekhanlder}
        />


      </FormGroup>


    </FormControl>
  );
} export default FilterDash;