import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

 function SimpleBackdrop(props) {

  return (
    <div style={{"position": "relative"}}>
      <Backdrop
        sx={{ position: props.complete ?"fixed":"relative",height:props.complete ?"100vh":props.configurations?"5vh":"90vh",color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}export default SimpleBackdrop;