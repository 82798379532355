import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Context } from '../../../../../../../../context/Context';
import { useContext, useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { Paper, Typography, Box } from '@mui/material';

const Title = ({ title = "" }) => {
    return (
        <Typography
            component="h2"
            variant="h6"
            color="primary"
            align="center"
            gutterBottom
        >
            {title}
        </Typography>
    );
};

function MultipleBarChar({ data , title = "title", height = 400 }) {
    const datacontext = useContext(Context);
    const [xAxis, setXaxis] = useState([]);
    const [transacciones, setTransacciones] = useState([]);
    const [personas, setPersonas] = useState([]);

    useEffect(() => {
        try{
            const nombres = data.map(item => item.fullname);
            const totalTransacciones = data.map(item => item.totalTransacciones);
            const totalPersonas = data.map(item => item.totalPersonas);
    
            setXaxis(nombres);
            setTransacciones(totalTransacciones);
            setPersonas(totalPersonas);
        }catch(e){}

    }, [data]);

    return (
        <ThemeProvider theme={datacontext.primaytheme}>
            <Paper>
                <Title title={title} />
                <BarChart
                   height={height}
                   xAxis={[
                    {
                        scaleType: 'band', 
                        data: xAxis,
                        tickLabelProps: () => ({
                            angle: -45,   // Rotar etiquetas
                            textAnchor: 'end',  // Alinear el texto al final para evitar solapamiento
                            fontSize: 12,  // Asegurar que el tamaño del texto no cause problemas
                        }),
                        tickSpacing: 1, // Forzar que se muestren todas las etiquetas
                    }
                ]}
                    series={[
                        { name: 'Total Transacciones', data: transacciones, color: datacontext.primaytheme.palette.primary.main },
                        { name: 'Total Personas', data: personas, color: datacontext.primaytheme.palette.secondary.main }
                    ]}
                />
                <Box display="flex" justifyContent="center" mt={2}>
                    <Box display="flex" alignItems="center" mr={2}>
                        <Box width={12} height={12} bgcolor={datacontext.primaytheme.palette.primary.main} mr={1} />
                        <Typography variant="body2">Total Transacciones</Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Box width={12} height={12} bgcolor={datacontext.primaytheme.palette.secondary.main} mr={1} />
                        <Typography variant="body2">Total Personas</Typography>
                    </Box>
                </Box>
            </Paper>
        </ThemeProvider>
    );
}

export default MultipleBarChar;
