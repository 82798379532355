import { Skeleton, Grid } from "@mui/material";
import Table from "../Table/Table";
import PayloadViewer from "../PayloadModal/PayloadViewer";
import ActionModal from "../../../../../../Universals/ActionModal";
import useIncidents from "./hooks/useIncidents";
const IncidentsComponent = ({ contentdashboard }) => {
  const {
    Refunds,
    Incidents,
    transactiondata,
    transactiondata2,
    openPayloadViewer,
    openPayloadViewer2,
    requestCompleteTransaction,
    requestMoveTransaction,
    keysEditablesIncidents,
    keysVisiblesIncidents,
    alltransactionsactive,
    handleComplete,
    handleComplete2,
    handlePageChange,
    handleClosePayloadViewer,
    handleCloseModal,
    onFinalActionButtonClicked,
    onScundaryActionButtonClicked,
    makeRequest
  } = useIncidents({ contentdashboard });

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/* Muestra Skeleton mientras la solicitud de transacción está en proceso */}
          {requestCompleteTransaction.loading ||
          requestMoveTransaction.loading ? (
            <Skeleton variant="rectangular" width="100%" height={300} />
          ) : (
            <Table
              title="Incidentes"
              headers={Incidents.headers && Incidents.headers}
              content={Incidents.data}
              totalElements={Incidents.totalSum}
              omitKeys={Incidents.omitkeys}
              onPageChange={handlePageChange}
              actionButtonTitle="Detalles de transacción"
              onActionButtonClick={handleComplete}
              standardPagination={alltransactionsactive}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          {/* Muestra Skeleton mientras la solicitud de transacción está en proceso */}
          {requestMoveTransaction.loading ? (
            <Skeleton variant="rectangular" width="100%" height={300} />
          ) : (
            <Table
              title="Posibles reembolsos"
              headers={Refunds.headers && Refunds.headers}
              content={Refunds.data}
              totalElements={Refunds.totalSum}
              omitKeys={Refunds.omitkeys}
              onPageChange={handlePageChange}
              actionButtonTitle="Detalles de transacción"
              standardPagination={alltransactionsactive}
              onActionButtonClick={handleComplete2}
            />
          )}
        </Grid>
      </Grid>
      {transactiondata.payload && (
        <PayloadViewer
          open={openPayloadViewer}
          onClose={handleClosePayloadViewer}
          transactiondata={transactiondata}
          keysEditables={keysEditablesIncidents}
          keysVisibles={keysVisiblesIncidents}
          onFinalActionButtonClicked={onFinalActionButtonClicked}
          onSecondaryActionButtonClicked={onScundaryActionButtonClicked}
          secondaryActionButtonLabel={"Mover a Posibles reembolsos"}
          refreshPage={() => {makeRequest()}}
        />
      )}

      {transactiondata2.payload && (
        <PayloadViewer
          open={openPayloadViewer2}
          onClose={handleClosePayloadViewer}
          transactiondata={transactiondata2}
          keysVisibles={keysVisiblesIncidents}
          onFinalActionButtonClicked={onScundaryActionButtonClicked}
          finalActionButtonLabel={"Mover a incidentes "}
        />
      )}

      <ActionModal
        open={requestCompleteTransaction.success !== null}
        onClose={handleCloseModal}
        text={
          requestCompleteTransaction?.success
            ? "La transacción se completo exitosamente!"
            : "Ocurrio un error al completar la transacción."
        }
      />
      <ActionModal
        open={requestMoveTransaction.success !== null}
        onClose={handleCloseModal}
        text={
          requestMoveTransaction?.success
            ? "La transacción se movio exitosamente!"
            : "Ocurrio un error al mover la transacción."
        }
      />
    </>
  );
};
export default IncidentsComponent;
