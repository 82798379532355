
import { useEffect, useState } from "react"
import { useContext } from "react";
import { Context } from "../../../../../../context/Context";
import { getCurrentDate, month, year } from "../../../../../../data/Datos";


const useAdvisorContent = ({ filter = "today", specificfilter = null }) => {
    const datacontext = useContext(Context);
    const [currentPage] = useState(1)
    const registPerPage = 10;
    const keysEditablesTransactions = [];
    const keysVisiblesTransactions = [
      "personas",
      "importe",
      "service_charge",
      "manual",
      "correo",
      "telefono",
      "detalles",
    ];
    const [TransactionsUsersOBJ, setTransactionsUsers] = useState
    ({Title: "Transacciones", Value: "-", SecundaryValue: "Este es el total de transacciones" ,numericvalue :0});
    const [PaxOBJ, setTransactionsPax] = useState
    ({Title: "Pax", Value: "-", SecundaryValue: "Este es el total de Pax registrados" ,numericvalue :0});
    const [TransactionsList, setTransactionsList] = useState({
        headers: ["Voucher", "Personas ", "Cantidad", "Cargo por servicio", "Codigo de transacción", "Asesor", "Terminal", "Fecha de partida", "Fecha de creación","Fecha de creación Mex","Manual"],
        data: [],
        totalSum: 0,
        omitkeys: ["id", "updatedAt", "payment", "dateSatQ", "hourSatQ", "useridSatQ", "idSatQ", "payload","comments"]
    });
    const [transactiondata, seTransactiondatavoucher] = useState({
        payload: "",
        transactionid: "",
        voucher: "",
        paymentgateway: "",
      });
    const [openPayloadViewer, setopenPayloadViewer] = useState(false);
    useEffect(() => {
        if (transactiondata.payload) setopenPayloadViewer(true);
      }, [transactiondata]);
    const ResetData = ()=>{
        setTransactionsUsers({ ...TransactionsUsersOBJ, Value: "-" ,numericvalue:0})
        setTransactionsPax({ ...PaxOBJ, Value: "-" ,numericvalue:0})
    }

    const transactionsUsersRecived = (response) => {
         setTransactionsUsers( {...TransactionsUsersOBJ,Value: response.data.data.totalSum});
        setTransactionsPax( {...PaxOBJ,Value: response.data.data.totalPerson});
        setTransactionsList({ ...TransactionsList, data:response.data.data.alltransaction, totalSum: response.data.data.totalSum });
    }
    const transactionsUsersFailed = (response) => {
        console.log(response);
        ResetData();
    }

    const handleTransactionSelected = (row) => {
        seTransactiondatavoucher({
          payload: JSON.parse(row.payload),
          transactionid: row.id,
          voucher: row.voucher,
          paymentgateway: row.openpayKey,
        });
      };

      const handleClose = () => {
        seTransactiondatavoucher({
          payload: "",
          transactionid: "",
          voucher: "",
          paymentgateway: "",
        });
        setopenPayloadViewer(false);
      };



    const makeRequest = () => {
        ResetData();
        let complementaryurl1 = ""
        if (filter.includes("today")) {
            complementaryurl1 = "?StartDate=" + getCurrentDate() + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + currentPage + "&PageSize=" + registPerPage            
        }
        else if (filter.includes("month")) {
            complementaryurl1 = "?StartDate=" + year() + "/" + month() + "/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + currentPage + "&PageSize=" + registPerPage
           
        }
        else if (filter.includes("year")) {
            complementaryurl1 = "?StartDate=" + year() + "/01/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + currentPage + "&PageSize=" + registPerPage
           
        }   
        else {     
            complementaryurl1 = specificfilter.url + "&PageNumber=" + currentPage + "&PageSize=" + registPerPage
        }
         datacontext.getTransactionUser(transactionsUsersRecived, transactionsUsersFailed, complementaryurl1+"&id="+ datacontext.LoggedUser.id, false )
    }


    useEffect(() => {
        makeRequest();
    }, [filter, specificfilter, currentPage])

    return { TransactionsUsersOBJ, PaxOBJ,TransactionsList,transactiondata,openPayloadViewer,keysEditablesTransactions,keysVisiblesTransactions,handleTransactionSelected,handleClose};




}
export default useAdvisorContent;