import { Snackbar, SnackbarContent, Typography, Grid } from "@mui/material";
import { NotificationContext } from "../../context/NotificationContext";
import { useEffect, useState, useContext } from "react";
import blanconegro from "../../assets/Images/blanconegrovisitax.png";

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                    color: '#000',
                    borderRadius: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '16px',
                    '@media (max-width:600px)': {
                        width: 'auto',
                        maxWidth: '50%',
                    },
                },
            },
        },
    },
});


const Notifications = () => {
    const { notification,setNotification } = useContext(NotificationContext);
    const [openNotification, setOpenNotification] = useState(false);
    
    useEffect(() => {
        if (notification.title) {
            setOpenNotification(true);
        }
    }, [notification])

    const handleClose = () => {
        setOpenNotification(false);
        setNotification({ title: '', body: '', image: '' })
    }

    return (<>
        <ThemeProvider theme={theme}>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openNotification}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <SnackbarContent
                    message={
                        <div>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={2}>
                                    <img
                                        src={notification.image || blanconegro}
                                        alt="Notification"
                                        style={{ 
                                            width: '30px', 
                                            height: '30px',
                                            '@media (max-width:600px)': {
                                                width: '20px',
                                                height: '20px',
                                            },
                                        }} 
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="body2" style={{ 
                                        fontWeight: 'bold', 
                                        textAlign: 'center',
                                        '@media (max-width:600px)': {
                                            fontSize: '0.7rem',
                                        },
                                    }}>
                                        {notification.title}
                                    </Typography>
                                    <Typography variant="caption">
                                        {notification.body}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    }
                />
            </Snackbar>
        </ThemeProvider>
    </>)
}

export default Notifications;
