import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../context/Context";
import { openpay3ds } from "../../../data/Datos";

export const usePretransaction = ({ setPaymentFail, setWaitingResponse }) => {
  const datacontext = useContext(Context);
  const txt = datacontext.myConfig.txt[datacontext.lenguage];
  const navigate = useNavigate();
  const [openpayOptions, setOpenPayOptions] = useState({
    openpayid: null,
    apikeyopenpay: null,
    sandboxmode: datacontext.Sandbox,
  });
  const [deviceSessionId, setDeviceSessionId] = useState(null);
  const [advisorsActive, setAdvisorsActice] = useState(true);

  const CreateToken = (idPretransaction, transactiondata) => {
    const successCallbacktoken = async (response) => {
      AskFor3ds(idPretransaction, response.data.id, transactiondata);
    };
    const errorCallbacktoken = (response) => {
      FailResponsePay(txt[76], false);
    };
    try {
      window.OpenPay.token.create(
        transactiondata.card,
        successCallbacktoken,
        errorCallbacktoken,
        {
          use_3d_secure: false,
        }
      );
    } catch (e) {
      console.error("Error en CreateToken:", e);
      FailResponsePay(txt[76]);
    }
  };

  const AskFor3ds = (idPretransaction, token, transactiondata) => {
    const success3Ds = async (res) => {
      try {
        if (
          res.data.method.includes("card") &&
          res.data.status.includes("completed")
        ) {
          //CompletePay(res.data.id, true, "", null);
          return;
        }

        if (
          res.data.method.includes("card") &&
          (res.data.status.includes("in_progress") ||
            res.data.status.includes("charge_pending"))
        ) {
          window.location.href = res.data.payment_method.url;
        }
      } catch (error) {
        console.error("Error en success3Ds:", error);
        FailResponsePay("Error processing 3DS.");
      }
    };

    const fail3Ds = (response) => {
      const failmessage = txt[77];
      FailResponsePay(failmessage ? failmessage : "Error desconocido", false);
    };
    try {
      datacontext.postchargeop3ds(success3Ds, fail3Ds, {
        ...openpay3ds,
        //use_3d_secure: datacontext.LoggedUser ? advisorsActive : true,
        use_3d_secure: advisorsActive,
        redirect_url:
          window.location.hostname +
          "/CompletePayment?idpretransaction=" +
          idPretransaction,
        amount: datacontext.Data.Datos.importe,
        source_id: token,
        device_session_id: deviceSessionId,
        description: datacontext.Data.Datos.detalles[0].pasaporte,
        customer: {
          name: transactiondata.card.holder_name,
          last_name: "",
          phone_number: transactiondata.card.phone_number,
          email: transactiondata.card.email,
        },
      });
    } catch (error) {
      console.error("Error en AskFor3ds:", error);
      FailResponsePay("Error starting the 3DS process.");
    }
  };

  const MakePretransaction = (
    onSuccesCallback,
    OnFailCallback,
    manual = false,
    comment = "Transaccion digital",
    paymentgateway = ""
  ) => {
    const Payload = {
      ...datacontext.Data.Datos,
      card: null,
      transaction_id: paymentgateway,
      manual: manual,
      coment: comment,
      voucherlink: "",
    };
    datacontext.postPreTransaction(onSuccesCallback, OnFailCallback, Payload);
  };

  const DigitalPayment = (totalPaymentData) => {
    const onSuccesPretransaction = (response) => {
      CreateToken(response.data.data.id, totalPaymentData);
    };
    const onFailPreTraction = (resp) => {
      FailResponsePay(
        "An unexpected error has ocurred, please try again",
        false
      );
    };
    MakePretransaction(onSuccesPretransaction, onFailPreTraction);
  };

  const WritenPayment = (totalPaymentData) => {
    const onSuccesPretransaction = (response) => {
      navigate(
        "/CompletePayment?idpretransaction=" +
          response.data.data.id +
          "&id=" +
          totalPaymentData?.approvalCode
      );
    };
    const onFailPreTraction = (resp) => {
      FailResponsePay(
        "An unexpected error has ocurred, please try again",
        false
      );
    };
    console.log(totalPaymentData);
    MakePretransaction(
      onSuccesPretransaction,
      onFailPreTraction,
      totalPaymentData?.approvalCode?.length < 15 ? true : false,
      totalPaymentData.paymentType === "physical"
        ? "Se realiza pago fisico"
        : totalPaymentData?.coment,
      totalPaymentData?.approvalCode
    );
  };

  const AndroidPayment = (totalPaymentData) => {
    const onSuccesPretransaction = (response) => {
      const newPaymentObject = {
        amount: datacontext.Data.Datos.importe.toString()+".00",
        orderId: response.data.data.id,
        email: datacontext.Data.Datos.correo,
        reference: datacontext.Data.Datos.detalles[0].pasaporte,
        businessName: "Visitax",
        token:datacontext.token,
      };

      const jsonData = JSON.stringify(newPaymentObject);
      if (typeof Android !== "undefined" && Android.receiveJson) {
        Android.receiveJson(jsonData);
      } else {
        console.log("Android interface is not available.");
      }
    };
    const onFailPreTraction = (resp) => {
      FailResponsePay(
        "An unexpected error has ocurred, please try again",
        false
      );
    };
    MakePretransaction(
      onSuccesPretransaction,
      onFailPreTraction,
      true,
      "Se realiza pago fisico desde aplicativo Android"
    );
  };

  const configureApi = () => {
    try {
      datacontext.myConfig.allconfigArray.map((value) => {
        if (value.concept.includes("OPENPAYID")) {
          setOpenPayOptions((prev) => ({
            openpayid: value.configurationInfo,
            apikeyopenpay: prev.apikeyopenpay,
            sandboxmode: prev.sandboxmode,
          }));
        }
        if (value.concept.includes("PUBLICAPIKEYOPENPAY")) {
          setOpenPayOptions((prev) => ({
            openpayid: prev.openpayid,
            apikeyopenpay: value.configurationInfo,
            sandboxmode: prev.sandboxmode,
          }));
        }
        if (value.concept.includes("3dsAdvisorsActive")) {
          setAdvisorsActice(value.configurationInfo == 1 ? true : false);
        }
      });
    } catch (error) {
      console.error("Error configuring OpenPay API:", error);
      FailResponsePay("Error configuring Gateway. Try again.");
    }
  };

  const FailResponsePay = (message = txt[77]) => {
    try {
      setPaymentFail(message);
      setWaitingResponse(false);
      configureApi();
    } catch (error) {
      console.error("Error en FailResponsePay:", error);
    }
  };

  useEffect(() => {
    try {
      if (
        window.OpenPay &&
        openpayOptions.openpayid != null &&
        openpayOptions.apikeyopenpay != null
      ) {
        window.OpenPay.setId(openpayOptions.openpayid);
        window.OpenPay.setApiKey(openpayOptions.apikeyopenpay);
        window.OpenPay.setSandboxMode(openpayOptions.sandboxmode);
        const deviceDataId = window.OpenPay.deviceData.setup("paymentForm");
        setDeviceSessionId(deviceDataId);
      }
    } catch (error) {
      console.error("Error en useEffect para OpenPay:", error);
      FailResponsePay();
    }
  }, [openpayOptions]);

  return {
    DigitalPayment,
    WritenPayment,
    AndroidPayment,
    configureApi,
  };
};
