import PlanetPonit from "../../assets/Images/PlanetaPuntosbackgroundoff.png";
import { Canvas, useFrame, useLoader, useThree } from "@react-three/fiber";
import { Sphere, OrbitControls } from "@react-three/drei";
import * as THREE from "three";
import { useRef } from "react";
import { TextureLoader } from "three";

const RotatingPlanet = () => {
  const meshRef = useRef(THREE.Mesh);
  const texture = useLoader(TextureLoader, PlanetPonit);
  const { viewport } = useThree();

  const responsiveRadius = Math.min(viewport.width, viewport.height) / 4;

  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.rotation.y += 0.005;
    }
  });

  return (
    <Sphere args={[responsiveRadius, 100, 100]} ref={meshRef}>
      <meshStandardMaterial
        map={texture}
        transparent
        opacity={1}
        side={THREE.DoubleSide}
      />
    </Sphere>
  );
};

const Planet = () => {
  return (
    <Canvas style={{ width: "100%", height: "100%" }}>
      <ambientLight intensity={1} />
      <directionalLight position={[6, 2, 5]} />
      <RotatingPlanet />
      <OrbitControls enablePan={false} enableZoom={false} />
    </Canvas>
  );
};

export default Planet;
