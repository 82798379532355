import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { differenceInDays, differenceInMonths, differenceInYears } from 'date-fns';

const Filters = ({ staticfilterhandler = (filter) => { console.log(filter) }, specificFilterClick = (specificdata) => { console.log(specificdata)} }) => {

  const [FirstDate, setFirstDate] = useState(dayjs());
  const [LasDate, setLastDate] = useState(dayjs());
  const [FilterSelected, setFilterSelected] = useState("today");

  const OnFirstDateChange = (date) => {
    setFirstDate(date);
  }
  const OnDateLastDateChange = (date) => {
    setLastDate(date);

  }
  const EspecificFilterChange = () => {
    const FirstMonth = FirstDate.$M < 9 ? "0" + (FirstDate.$M + 1) : FirstDate.$M + 1;
    const FirstDay = FirstDate.$D < 9 ? "0" + (FirstDate.$D) : FirstDate.$D;
    const FirstYear = FirstDate.$y;

    const LastMonth = LasDate.$M < 9 ? "0" + (LasDate.$M + 1) : LasDate.$M + 1;
    const LastDay = LasDate.$D < 9 ? "0" + (LasDate.$D) : LasDate.$D;
    const LastYear = LasDate.$y;

    const url = "?StartDate=" + FirstYear + "/" + FirstMonth + "/" + FirstDay + " 00:00:00&EndDate=" + LastYear + "/" + LastMonth + "/" + LastDay + " 23:59:59";

    let type = "D"; // Default type

    const firstDateObj = new Date(FirstYear, FirstMonth - 1, FirstDay);
    const lastDateObj = new Date(LastYear, LastMonth - 1, LastDay);

    const daysDifference = differenceInDays(lastDateObj, firstDateObj);
    const monthsDifference = differenceInMonths(lastDateObj, firstDateObj);
    const yearsDifference = differenceInYears(lastDateObj, firstDateObj);

    if (daysDifference === 0) {
        type = "H";
    } else if (daysDifference <= 30) {
        type = "D";
    } else if (monthsDifference <= 12) {
        type = "M";
    } else if (yearsDifference > 1) {
        type = "Y";
    }

    specificFilterClick({ url: url, type: type });
    staticfilterhandler("select");
}

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" >Selecciona un filtro</FormLabel>
      <Accordion expanded={FilterSelected.includes("select")}>
        <AccordionSummary
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <FormControlLabel
            value="top"
            control={<Checkbox />}
            label="Selecciona rango"
            labelPlacement="start"
            checked={FilterSelected.includes("select")}
            onClick={() => setFilterSelected("select")}
          />
        </AccordionSummary>
        <AccordionDetails>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker', 'DatePicker']}>
              <DatePicker label="Fecha inicial" defaultValue={dayjs()} onChange={OnFirstDateChange} value={FirstDate} />
              <DatePicker
                label="Fecha Final" defaultValue={dayjs()} onChange={OnDateLastDateChange} value={LasDate}
              />
            </DemoContainer>
          </LocalizationProvider>
        </AccordionDetails>
        <AccordionActions>
          <Button onClick={EspecificFilterChange}>Buscar</Button>
        </AccordionActions>
      </Accordion>
      <FormGroup aria-label="position" row>

        <FormControlLabel
          value="top"
          control={<Checkbox />}
          label="Hoy"
          labelPlacement="start"
          checked={FilterSelected.includes("today")}
          onClick={() => setFilterSelected("today")}
          onChange={() => staticfilterhandler("today")}
        />
        <FormControlLabel
          value="top"
          control={<Checkbox />}
          label="Mes en curso"
          labelPlacement="start"
          checked={FilterSelected.includes("month")}
          onClick={() => setFilterSelected("month")}
          onChange={() => staticfilterhandler("month")}
        />
        <FormControlLabel
          value="top"
          control={<Checkbox />}
          label="Año en curso"
          labelPlacement="start"
          checked={FilterSelected.includes("year")}
          onClick={() => setFilterSelected("year")}
          onChange={() => staticfilterhandler("year")}
        />


      </FormGroup>


    </FormControl>
  );
}
export default Filters;